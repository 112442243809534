// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiEndPoint: 'https://truleadapi.lupin.com',
  apiEndPoint: 'https://encryptiontestapi.truleadai.com',
  // apiEndPoint: 'http://127.0.0.1:5000', 
  recaptcha_sitekey: '6LclPrQcAAAAADFNBmvxG16QLMGy8YWqNGvmyVdD',
  firebase: {
    apiKey: "AIzaSyDErZbfLLndXR-hLIYr9Beeae6v63yrUSw",
    authDomain: "trulead-ff537.firebaseapp.com",
    databaseURL: "https://trulead-ff537-default-rtdb.asia-southeast1.firebasedatabase.app/",
    projectId: "trulead-ff537",
    storageBucket: "trulead-ff537.appspot.com",
    messagingSenderId: "809139602824",
    appId: "1:809139602824:web:98dac5c712e03c1e8b263d"
  },
   azure: {
    appId: 'c7f3424b-afcd-4eaa-9a60-a0b7c7ea57ff',
    redirectUri: 'https://trulead.lupin.com',
    scopes: [
      "user.read",
    ]
  },
  // firebase: {
  //   apiKey: "AIzaSyDqZn6gno9dipDFBl4RSRBgCVX0-rb3BaU",
  //   authDomain: "hrapptrulead.firebaseapp.com",
  //   projectId: "hrapptrulead",
  //   databaseURL: "https://hrapptrulead-default-rtdb.firebaseio.com/",
  //   storageBucket: "hrapptrulead.appspot.com",
  //   messagingSenderId: "805458700336",
  //   appId: "1:805458700336:web:f9d557a877255c0c01a5f5",
  //   measurementId: "G-9K5C8D3RYL"
  // }


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
