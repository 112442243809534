import {environment} from 'src/environments/environment';
import {Injectable, NgZone} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
// import {auth} from 'firebase/app';
import {Router} from '@angular/router';
// import {AngularFireAuth} from '@angular/fire/auth';
// import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {
  Activity,
  ActivityAttemptFeedbackInvitation,
  ActivityAttemptFeedbackInvitationsRetrieveRequest,
  ActivityFeedbackQuestion,
  ActivityFeedbackQuestionsRetrieveRequest,
  ActivityMapping,
  ActivityMappingRetrieveRequest,
  ActivityQuestion,
  ActivityQuestionsRetrieveRequest,
  ActivityRetrieveRequest,
  AnnualPriority,
  APRetrieveRequest,
  Assessment,
  AssessmentActivityAdminViewCount,
  AssessmentActivityAdminViewCountsRetrieveRequest,
  AssessmentActivityAttempt,
  AssessmentActivityAttemptsRetrieveRequest,
  AssessmentActivityFeedbackAttempt,
  AssessmentActivityFeedbackAttemptGroup,
  AssessmentActivityFeedbackAttemptsGroupRetrieveRequest,
  AssessmentActivityFeedbackAttemptsRetrieveRequest,
  AssessmentActivityQuestionAttempt,
  AssessmentActivityQuestionAttemptGroup,
  AssessmentActivityQuestionAttemptsGroupRetrieveRequest,
  AssessmentActivityQuestionAttemptsRetrieveRequest,
  AssessmentAttempts,
  AssessmentAttemptsRetrieveRequest,
  AssessmentRetrieveRequest,
  Behaviour,
  BehaviourRetrieveRequest,
  BusinessUnit,
  BusinessUnitRetrieveRequest,
  CalenderActivities,
  CalenderActivitiesRetrieveRequest,
  CampaignEmployees,
  CampaignEmployeesRetrieveRequest,
  CampaignItemGridView,
  CampaignItemGridViewRetrieveRequest,
  CampaignItemsTree,
  CampaignItemsTreeRetrieveRequest,
  Campaigns,
  CampaignsRetrieveRequest,
  CDEmpParticipationRetrieveRequest,
  CDTopsRetrieveRequest,
  CDTxnCountRetrieveRequest,
  CDTxnTrendRetrieveFilterRequest,
  CDTxnTrendRetrieveLevelNameRequest,
  CDTxnTrendRetrieveRequest,
  Competency,
  CompetencyRetrieveRequest,
  ConsensusLog,
  ConsensusLogsRetrieveRequest,
  CriticalRole,
  CriticalRoleEmployeeMap,
  CriticalRoleEmployeeMapLog,
  CriticalRoleEmployeeMapLogRetieveRequest,
  CriticalRoleEmployeeMapRetieveRequest,
  CriticalRoleRetieveRequest,
  Department,
  DepartmentRetrieveRequest,
  DesiredLevel,
  DesiredLevelRetrieveRequest,
  Employee,
  EmployeeCSVSampleTemplateDoc,
  EmployeeCSVSampleTemplateDocRetrieveRequest,
  EmployeeInitiative,
  EmployeeInitiativeRetrieveRequest,
  EmployeeInitiativesGroup,
  EmployeeInitiativesGroupsRetrieveRequest,
  EmployeeInitiativeStatus,
  EmployeeInitiativeStatusRetrieveRequest,
  EmployeeObjective,
  EmployeeObjectiveGroup,
  EmployeeObjectiveGroupsRetrieveRequest,
  EmployeeObjectiveRetrieveRequest,
  EmployeePii,
  EmployeePiiRetrieveRequest,
  EmployeeRetrieveRequest,
  EmployeeTransaction,
  EmployeeTransactionRetrieveRequest,
  FeedComment,
  FeedCommentsRetrieveRequest,
  FeedLike,
  FeedLikesRetrieveRequest,
  Form,
  FormLaunch,
  FormLaunchRetrieveRequest,
  FormRetrieveRequest,
  FunctionRetrieveRequest,
  GapAnalysys,
  GapAnalysysByFilter,
  GapAnalysysByFilterRetrieveRequest,
  GapAnalysysReportRetrieveRequest,
  GapAnalysysRetrieveRequest,
  getOverallOfReportRetriveRequest,
  Grade,
  GradeRetrieveRequest,
  HeatMapRetrieveRequest,
  HeatMaps,
  HybridDrillDown,
  HybridDrillDownRetrieveRequest,
  LabelledContent,
  LabelledContentRetrieveRequest,
  Location,
  LocationRetrieveRequest,
  ManagerRole,
  ManagerRoleRetrieveRequest,
  Managers,
  MaximiserSettings,
  MaximiserSettingsRetrieveRequest,
  MovingBullet,
  MovingBulletRetrieveRequest,
  NewsFeed,
  NewsFeedRetrieveRequest,
  NineBoxChart,
  NineBoxChartRetrieveRequest,
  NotificationRetrieveRequest,
  NotificationTemplate,
  NotificationTemplatesRetrieveRequest,
  ObjectiveMaster,
  ObjectiveMasterRetrieveRequest,
  PerfomanceData,
  PerfomanceDataRetrieveRequest,
  Premium_360,
  Premium_360_Questions,
  Premium_360_QuestionsRetrieveRequest,
  Premium_360RetrieveRequest,
  Project,
  ProjectRetrieveRequest,
  PsychometricAssessment,
  PsychometricAssessmentGroup,
  PsychometricAssessmentGroupRetrieveRequest,
  PsychometricAssessmentRetrieveRequest,
  ReferenceData,
  ReferenceDataRetrieveRequest,
  ReinforcementAssessmentStatus,
  ReinforcementAssessmentStatusRetrieveRequest,
  RetrieveRequest,
  Role,
  RoleObjective,
  RoleObjectiveRetrieveRequest,
  RoleRetrieveRequest,
  RoleSpAp,
  RoleSpApRetrieveRequest,
  SMDashboardRequest,
  SMEmployeesDashboard,
  SMEmployeesDashboardRequest,
  SMSuccessMatrixRequest,
  SPRetrieveRequest,
  StrategicPerspective,
  Survey,
  SurveyRetrieveRequest,
  Surveys,
  SurveysRetrieveRequest,
  Tags,
  TagsRetrieveRequest,
  TalentcardRetrieveRequest,
  Tenant,
  TenantRetrieveRequest,
  ThreeSixtyDegreeFeedbackList,
  ThreeSixtyDegreeFeedbackListRetrieveRequest,
  Ticket,
  TicketRetrieveRequest,
  VideoNote,
  VideoNotesRetrieveRequest
} from './app.models';


export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}


@Injectable({
  providedIn: 'root',
})
export class AppService {
  apiEndPoint = environment.apiEndPoint;
  stratIndex: number = 0;
  endIndex: number = 2;
  launched: string = '';
  tempStr: any = 'tttttt yyyyyy uuuuuu nnnnnnn gggggggg kkkkkkkk oooooooo llllllll';
  interval: any;

  constructor(public http: HttpClient,
              // public afs: AngularFirestore,
              // public afAuth: AngularFireAuth,
              public router: Router,
              public ngZone: NgZone) {
  }

  keyReload;
  // Used for gap Anaysys Report
  gapAnalaysysEmployeesSelected = [];
  gapAnalysysLevelSelected;
  // End

  // LR Notification Key add
  singleMsg: any;

  // gapAssessmentName
  gapAssessmentName = [];

// -----------------------------
// Ticket Related APIs
// -----------------------------
  public getReferenceData(
    rr: ReferenceDataRetrieveRequest = null
  ): Observable<ReferenceData[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_date',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      priority_ids: rr ? rr.priority_ids : [],
      severity_ids: rr ? rr.severity_ids : [],
      module_ids: rr ? rr.module_ids : [],
      status_type: rr ? rr.status_type : 'admin'

    };
    return this.get<ReferenceData[]>(
      '/api/ticket-reference-data?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getTicket(
    rr: TicketRetrieveRequest = null
  ): Observable<Ticket[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_date',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      tenant_ids: rr ? rr.tenant_ids : [],
      priority_ids: rr ? rr.priority_ids : [],
      severity_ids: rr ? rr.severity_ids : [],
      module_ids: rr ? rr.module_ids : [],
      tenant_view: rr ? rr.tenant_view : 'no'

    };
    return this.get<Ticket[]>(
      '/api/ticket?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }


  public createTicket(data): Observable<any> {
    return this.post('/api/ticket', data);
  }

  public updateTicket(data, id): Observable<any> {
    return this.put('/api/ticket/' + id, data);
  }

  public createComments(data): Observable<any> {
    return this.post('/api/ticket-comment', data);
  }

  public getComments(id): Observable<any> {
    return this.get('/api/ticket-comment/' + id);
  }

  public getTicketUser() {
    // console.log("TICKET USER")
    return this.get('/api/ticket-user');
  }

// File Upload
  public ticketFilesUpload(data): Observable<any> {
    let isformData = true;
    return this.http.post(this.apiEndPoint + '/api/ticket-attatchment', data, {
      headers: this.getHeaders(isformData),
    });
  }


  // -----------------------------
  // Ticket Dashboard related APIs
  // -----------------------------

  public getTicketPieChart(): Observable<any> {
    return this.get('/api/ticket-dashboard-piechart');
  }

  public getTicketBarChart(filter) {
    return this.get('/api/ticket-dashboard?f=' + btoa(encodeURIComponent(JSON.stringify(filter))));
  }

  // -----------------------------
  // Business Unit related APIs
  // -----------------------------
  public getBusinessUnits(
    rr: BusinessUnitRetrieveRequest = null
  ): Observable<BusinessUnit[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      name: rr ? rr.name : '',
      ids: rr ? rr.ids : '',
    };
    return this.get<BusinessUnit[]>(
      '/api/business-units?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateBusinessUnit(data, id): Observable<any> {
    return this.put('/api/business-units/' + id, data);
  }

  public createBusinessUnit(data): Observable<any> {
    return this.post('/api/business-units', data);
  }

  public deleteBusinessUnit(id): Observable<any> {
    return this.delete('/api/business-units/' + id);
  }

  // -----------------------------
  // Location related APIs
  // -----------------------------
  public getLocations(
    rr: LocationRetrieveRequest = null
  ): Observable<Location[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      name: rr ? rr.name : '',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : [],
    };
    // console.log('Filters : ', filters);

    return this.get<Location[]>(
      '/api/locations?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateLocation(data, id): Observable<any> {
    return this.put('/api/locations/' + id, data);
  }

  public createLocation(data): Observable<any> {
    return this.post('/api/locations', data);
  }

  public deleteLocation(id): Observable<any> {
    return this.delete('/api/locations/' + id);
  }

  public getLocationbyId(id): Observable<any> {
    return this.get('/api/locations/' + id);
  }

  // -----------------------------
  // Function related APIs
  // -----------------------------
  public getFunctions(
    rr: FunctionRetrieveRequest = null
  ): Observable<Function[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      name: rr ? rr.name : '',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : [],
    };

    return this.get<Function[]>(
      '/api/functions?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateFunction(data, id): Observable<any> {
    return this.put('/api/functions/' + id, data);
  }

  public createFunction(data): Observable<any> {
    return this.post('/api/functions', data);
  }

  public deleteFunction(id): Observable<any> {
    return this.delete('/api/functions/' + id);
  }

  // -----------------------------
  // Department related APIs
  // -----------------------------
  public getDepartments(
    rr: DepartmentRetrieveRequest = null
  ): Observable<Department[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      function_ids: rr ? rr.function_ids : [],
      name: rr ? rr.name : '',
    };

    return this.get<Department[]>(
      '/api/departments?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateDepartment(data, id): Observable<any> {
    return this.put('/api/departments/' + id, data);
  }

  public createDepartment(data): Observable<any> {
    return this.post('/api/departments', data);
  }

  public deleteDepartment(id): Observable<any> {
    return this.delete('/api/departments/' + id);
  }

  // -----------------------------
  // Grade related APIs
  // -----------------------------
  public getGrades(rr: GradeRetrieveRequest = null): Observable<Grade[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      grade_name: rr ? rr.grade_name : '',
      business_unit_ids: rr ? rr.business_unit_ids : [],
    };

    return this.get<Grade[]>('/api/grades?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public updateGrade(data, id): Observable<any> {
    return this.put('/api/grades/' + id, data);
  }

  public createGrade(data): Observable<any> {
    return this.post('/api/grades', data);
  }

  public deleteGrade(id): Observable<any> {
    return this.delete('/api/grades/' + id);
  }

  public getGradebyId(id): Observable<any> {
    return this.get('/api/grades/' + id);
  }

  // -----------------------------------
  // Strategic Perspective related APIs
  // -----------------------------------

  public getStrategicPerspectives(
    rr: SPRetrieveRequest = null
  ): Observable<StrategicPerspective[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      title: rr ? rr.title : '',
      ids: rr ? rr.ids : '',
      is_active: rr ? rr.is_active : '',
      short_code: rr ? rr.short_code : '',
    };
    return this.get<StrategicPerspective[]>(
      '/api/strategic-perspectives?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateStrategicPerspective(data, id): Observable<any> {
    return this.put('/api/strategic-perspectives/' + id, data);
  }

  public createStrategicPerspective(data): Observable<any> {
    return this.post('/api/strategic-perspectives', data);
  }

  public deleteStrategicPerspective(id): Observable<any> {
    return this.delete('/api/strategic-perspectives/' + id);
  }

  public updateRoleStrategicPerspective(data, id): Observable<any> {
    return this.put('/api/role-strategic-perspectives/' + id, data);
  }

  // -------------------------------
  // Annual Priority related APIs
  //-------------------------------

  public getAnnualPriorities(
    rr: APRetrieveRequest = null
  ): Observable<AnnualPriority[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      title: rr ? rr.title : '',
      ids: rr ? rr.ids : '',
      is_active: rr ? rr.is_active : '',
      short_code: rr ? rr.short_code : '',
      sp_id: rr ? rr.sp_id : null,
    };
    return this.get<AnnualPriority[]>(
      '/api/annual-priorities?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateAnnualPriority(data, id): Observable<any> {
    return this.put('/api/annual-priorities/' + id, data);
  }

  public createAnnualPriority(data): Observable<any> {
    return this.post('/api/annual-priorities', data);
  }

  public deleteAnnualPriority(id): Observable<any> {
    return this.delete('/api/annual-priorities/' + id);
  }

  //-------------------------------
  // Objective Masters related APIs
  //-------------------------------

  public getObjectiveMasters(
    rr: ObjectiveMasterRetrieveRequest = null
  ): Observable<ObjectiveMaster[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      title: rr ? rr.title : '',
      ids: rr ? rr.ids : '',
      short_code: rr ? rr.short_code : '',
      ap_id: rr ? rr.ap_id : null,
    };
    return this.get<ObjectiveMaster[]>(
      '/api/objective-masters?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateObjectiveMaster(data, id): Observable<any> {
    return this.put('/api/objective-masters/' + id, data);
  }

  public createObjectiveMaster(data): Observable<any> {
    return this.post('/api/objective-masters', data);
  }

  public deleteObjectiveMaster(id): Observable<any> {
    return this.delete('/api/objective-masters/' + id);
  }

  //-------------------------
  // Role related APIs
  //-------------------------

  public getRoles(rr: RoleRetrieveRequest = null): Observable<Role[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      name: rr ? rr.role_name : '',
      ids: rr ? rr.ids : '',
    };
    return this.get<Role[]>('/api/roles?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public updateRole(data, id): Observable<any> {
    return this.put('/api/roles/' + id, data);
  }

  public createRole(data): Observable<any> {
    return this.post('/api/roles', data);
  }

  public deleteRole(id): Observable<any> {
    return this.delete('/api/roles/' + id);
  }

  // making get request
  public get<T>(url) {
    return this.http.get<T>(this.apiEndPoint + url, {
      headers: this.getHeaders(),
    });
  }

  // making delete request
  public delete(url) {
    return this.http.delete(this.apiEndPoint + url, {
      headers: this.getHeaders(),
    });
  }

  // making post request
  public post(url, data) {
    return this.http.post(this.apiEndPoint + url, data, {
      headers: this.getHeaders(),
    });
  }

  public put(url, data) {
    return this.http.put<any>(this.apiEndPoint + url, data, {
      headers: this.getHeaders(),
    });
  }

  public postFormData(url, data) {
    let isformData = true;
    return this.http.post(this.apiEndPoint + url, data, {
      headers: this.getHeaders(isformData),
    });
  }

  public putFormData(url, data) {
    let isformData = true;
    return this.http.put(this.apiEndPoint + url, data, {
      headers: this.getHeaders(isformData),
    });
  }

  public getHeaders(isformData = false) {
    let headers = new HttpHeaders();
      // .set('X-Requested-With', 'XMLHttpRequest');
      // .set('Access-Control-Allow-Origin', '*');
    if (isformData !== true) {
      headers = headers.set('content-type', 'application/json');
    }
    if (sessionStorage.getItem('accountAccessToken') != null) {
      headers = headers.set(
        'Authorization',
        'Bearer ' + sessionStorage.getItem('accountAccessToken')
      );
    } else {
      headers = headers.set(
        'Authorization',
        'Bearer ' + localStorage.getItem('dupaccountAccessToken')
      );
    }
    return headers;
  }

// User Story : SSO login
// Done by : Jayaram G
// Done on : 19/08/2021

// Calling the firebase providers

  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  // MsAuth() {
  //   return this.AuthLogin(new auth.OAuthProvider('microsoft.com'));
  // }

  // AppleAuth() {
  //   return this.AuthLogin(new auth.OAuthProvider('apple.com'));
  // }


// Authorization return after firebase Login

  // AuthLogin(provider) {
  //   return this.afAuth.signInWithPopup(provider)
  //     .then((result) => {
  //       const body = {'auth':result.user, 'login_from':'web'};
  //       this.http.post<any>(`${this.apiEndPoint}/api/verify/`, body)
  //         .subscribe((data) => {

  //           console.log('result', data);
  //           if (data.status == 'success') {
  //             this.ngZone.run(() => {
  //               sessionStorage.setItem('accountAccessToken', data['token']);
  //               sessionStorage.setItem('tokenexpiry', data['token_expiry']);
  //               this.getLoggedInUserDetail().subscribe(res => {
  //                 sessionStorage.setItem('me', btoa(JSON.stringify(res)));
  //                 this.router.navigate(['/']);
  //               });
  //             });
  //           }
  //         }, error => {
  //           if (error.error.message) {

  //             Swal.fire('', error.error.message, 'warning');
  //           } else if (error.error.message) {

  //             Swal.fire('', error.error.message, 'warning');
  //           } else {
  //             console.log(error);
  //             Swal.fire('', 'unknown Error', 'warning');
  //           }

  //         });

  //       this.SetUserData(result.user);
  //     }).catch((error) => {
  //       console.log(error);
  //       Swal.fire('', 'SSO uninitialised', 'warning');
  //     });
  // }

  // SetUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const userState: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     emailVerified: user.emailVerified
  //   };
  //   return userRef.set(userState, {
  //     merge: true
  //   });
  // }

  // public collect_data(user): Observable<any> {
  //   let data = {
  //     "username": user
  //   };
  //   let headers = this.getHeaders();
  //   headers = headers.set("Content-Type", 'application/json');
  //   console.log(JSON.stringify(data))
  //   return this.post('/api/roles', data);
  // }


  public checkValidEmail(email) {
    const data = {
      username: email,
      login_from: 'web'
    };
    let headers = this.getHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post(this.apiEndPoint + '/api/first_login_check/', JSON.stringify(data), {headers});
  }

  public checkValidOtp(email, otp) {
    const data = {
      username: email,
      otp: otp

    };
    let headers = this.getHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post(this.apiEndPoint + '/api/check_otp/', JSON.stringify(data), {headers});
  }

  public resetPassword(email, token, password) {
    const data = {
      username: email,
      token: token,
      password: password


    };
    let headers = this.getHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post(this.apiEndPoint + '/api/reset_password/', JSON.stringify(data), {headers});
  }


  public getMultiFactor(username, password) {
    let authorize = btoa(username + ':' + password);
    let headers = this.getHeaders();
    headers = headers.set('Authorization', 'basic ' + authorize);
    return this.http.post(this.apiEndPoint + '/api/multi_factor/', '', {
      headers: headers,
    });
  }


  public getAfterMultiFactor(jwt, otp) {
    // let authorize = btoa(username + ':' + password);
    const data = {otp: otp};
    let headers = this.getHeaders();
    headers = headers.set('Jwt-Token', jwt);
    return this.http.post(this.apiEndPoint + '/api/check_jwt_otp/', JSON.stringify(data), {
      headers,
    });
  }


  public getAuthorizationToken(username, password) {
    let authorize = btoa(encodeURIComponent(username + ':' + password));
    let headers = this.getHeaders();
    headers = headers.set('Authorization', 'basic ' + authorize);
    return this.http.post(this.apiEndPoint + '/api/tokens/', '', {
      headers: headers,
    });
  }

  public getLoggedInUserDetail() {
    return this.get('/api/users/me');
  }

  public getMe() {
    if (sessionStorage.getItem('me') != null) {
      return JSON.parse(decodeURIComponent(atob(sessionStorage.getItem('me'))));
    } else {
      return JSON.parse(decodeURIComponent(atob(localStorage.getItem('dupme'))));
    }

    return null;
  }

  // Remove user token after signout
  public removeUserToken() {

    return this.delete('/api/tokens/');
  }

  public getForgotOtp(email) {
    let data = {
      'username': email
    };
    return this.post('/api/login_otp', JSON.stringify(data));
  }

  // Remove user token ends here

  // -----------------------------
  // Competency related APIs
  // -----------------------------
  public getCompetencies(
    rr: CompetencyRetrieveRequest = null
  ): Observable<Competency[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      competency_name: rr ? rr.competency_name : '',
      level_id: rr ? rr.level_id : '',
      parent_id: rr ? rr.parent_id : '',
    };

    return this.get<Competency[]>(
      '/api/competencies?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getGraphAnalysysById(gapAnalysysId) {
    const filter = {survey_ids: [gapAnalysysId]};
    return this.get('/api/survey_attempt?f=' + btoa(encodeURIComponent(JSON.stringify(filter))));
  }

  public getSurveyQuestionsById(surveyid) {
    return this.get('/api/survey_questions/' + surveyid);
  }

  public updateCompetency(data, id): Observable<any> {
    return this.put('/api/competencies/' + id, data);
  }

  public createCompetency(data): Observable<any> {
    return this.post('/api/competencies', data);
  }

  public deleteCompetency(id): Observable<any> {
    return this.delete('/api/competencies/' + id);
  }


  // -----------------------------
  // Desired Level related APIs
  // -----------------------------

  public getDesiredLevel(
    rr: DesiredLevelRetrieveRequest = null
  ): Observable<DesiredLevel[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      function_id: rr ? rr.function_id : '',
      role_id: rr ? rr.role_id : '',
      employee_grade_id: rr ? rr.employee_grade_id : '',
      competency_id: rr ? rr.competency_id : '',
      ids: rr ? rr.ids : rr ? rr.function_id : '',
    };

    return this.get<DesiredLevel[]>(
      '/api/desired-level?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateDesiredLevel(data, id): Observable<any> {
    return this.put('/api/desired-level-new/' + id, data);
  }

  public createDesiredLevel(data): Observable<any> {
    return this.post('/api/desired-level-new', data);
  }

  public deleteDesiredLevel(id): Observable<any> {
    return this.delete('/api/desired-level/' + id);
  }


  // -----------------------------
  // Tenant related APIs
  // -----------------------------

  public getTenants(
    rr: TenantRetrieveRequest = null): Observable<Tenant[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
    };
    return this.get<Tenant[]>(
      '/api/tenant?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getTenantbyId(id): Observable<any> {
    return this.get('/api/tenant/' + id);
  }

  public createTenant(data): Observable<any> {
    // console.log("POST DATA", data)
    return this.postFormData('/api/tenant', data);
  }

  public updateTenant(data, id): Observable<any> {
    return this.put('/api/tenant/' + id, data);
  }

  public deleteTenant(id): Observable<any> {
    return this.delete('/api/tenant/' + id);
  }


  // -----------------------------
  // Employee related APIs
  // -----------------------------
  public getEmployees(
    rr: EmployeeRetrieveRequest = null
  ): Observable<Employee[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      emp_ids: rr ? rr.emp_ids : '',
      display_name: rr ? rr.display_name : '',
      reporting_manager: rr ? rr.reporting_manager : '',
      email_id: rr ? rr.email_id : '',
      // "campaign_item_id": rr ? rr.campaign_item_id: '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      notification_sent: rr ? rr.notification_sent : ''

    };

    return this.get<Employee[]>(
      '/api/employees?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }


  public getEmployeesSearch(
    rr: EmployeeRetrieveRequest = null
  ): Observable<Employee[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      emp_ids: rr ? rr.emp_ids : '',
      display_name: rr ? rr.display_name : '',
      email_id: rr ? rr.email_id : '',
      variable_filter_key: rr ? rr.variable_filter_key : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      department_ids: rr ? rr.department_ids : '',

    };

    return this.get<Employee[]>(
      '/api/employees-search?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }


  public getAllEmployeesById(data) {
    return this.post('/api/get_employees', data);
  }

  public getEmployeeForFilter(filters) {
    return this.get('/api/employees?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public updateEmployee(data, id): Observable<any> {
    return this.putFormData('/api/employees/' + id, data);
  }

  public createEmployee(data): Observable<any> {
    return this.postFormData('/api/employees', data);
  }

  public createEmployeeAdminFilter(data): Observable<any> {
    return this.post('/api/sub_admin', data);
  }

  public updateEmployeeAdminFilter(data): Observable<any> {
    return this.put('/api/sub_admin', data);
  }

  public getEmployeeAdminFilterById(id) {
    return this.get('/api/sub_admin/' + id);
  }

  public deleteEmployee(id): Observable<any> {
    return this.delete('/api/employees/' + id);
  }

  public getEmployeeById(id) {
    return this.get('/api/employees/' + id);
  }


  public postFilterDataByFilter(data) {
    return this.post('/api/employee_variable_filter', data);
  }

  public putFilterDataByFilter(id, data) {
    return this.put('/api/employee_variable_filter/' + id, data);
  }

  public getFilterDataByFilter(rr: any) {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      filter_key: rr ? rr.filter_key : '',
      ids: rr ? rr.ids : [],
      employee_id: rr ? rr.employee_id : '',
      employee_ids: rr ? rr.employee_ids : '',
      filter_employee_id: rr ? rr.filter_employee_id : '',
    };

    return this.get(
      '/api/employee_variable_filter?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public sendNotification(empIds) {
    return this.post('/api/employees/send_invitation', empIds);
  }


  // Time Zone Related APIs


  public createEmployeeTimeZone(data): Observable<any> {
    return this.post('/api/user_timezone', data);
  }

  public updateEmployeeTimeZone(data, id): Observable<any> {
    return this.put('/api/user_timezone/' + id, data);
  }

  public getEmployeeTimeZoneById(id) {
    return this.get('/api/user_timezone/' + id);
  }


  // -----------------------------
  // Campaign Employee related APIs
  // -----------------------------
  public getCampaignEmployees(
    rr: CampaignEmployeesRetrieveRequest = null
  ): Observable<CampaignEmployees[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 500,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      txn_type: rr ? rr.txn_type : '',
      emp_id: rr ? rr.emp_id : '',
      campaign_id: rr ? rr.campaign_id : '',
    };

    return this.get<CampaignEmployees[]>(
      '/api/campaigns/employees?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  // -----------------------------
  // Assessments related APIs
  // -----------------------------
  public getAssessments(
    rr: AssessmentRetrieveRequest = null
  ): Observable<Assessment[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      assessment_type: rr ? rr.assessment_type : '',
      ids: rr ? rr.ids : [],
      emp_id: rr ? rr.emp_id : null,
      is_assessor: rr ? rr.is_assessor : null,
      launched: rr ? rr.launched : ['yes', 'no'],
      // "name": rr ? rr.name : '',
    };
    // console.log('assessmenyt filters : ', JSON.stringify(filters));
    return this.get<Assessment[]>(
      '/api/assessments?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  //Assessmetn Attemp services Addedd

  public getAssessmentAttemps(
    tt: AssessmentAttemptsRetrieveRequest = null
  ): Observable<AssessmentAttempts[]> {
    let filters = {
      page: tt ? tt.page : 1,
      per_page: tt ? tt.per_page : 100,
      order_by: tt ? tt.order_by : 'id',
      sort_order: tt ? tt.sort_order : 'desc',
      ids: tt ? tt.ids : [],
      assessment_ids: tt ? tt.assessment_ids : null,
      emp_id: tt ? tt.emp_id : null,
    };

    return this.get<AssessmentAttempts[]>(
      '/api/assessment-attempts-filter?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }


  public getSurveys(
    rr: SurveyRetrieveRequest = null
  ): Observable<Survey[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      launched: rr ? rr.launched : 'yes',
      ids: rr ? rr.ids : [],
      emp_ids: rr ? rr.emp_ids : [],
      // "name": rr ? rr.name : '',
    };
    const filters1 = {emp_ids: [45]};

    return this.get<Survey[]>(
      '/api/survey?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  // public updateAssessment(data, id): Observable<any> {
  //   return this.put('/api/assessments/' + id, data);
  // }

  public updateAssessment(data, id): Observable<any> {
    return this.putFormData('/api/assessments/' + id, data);
  }

  // public createAssessment(data): Observable<any> {
  //   return this.post('/api/assessments', data);
  // }

  public createAssessment(data): Observable<any> {
    return this.postFormData('/api/assessments', data);
  }

  public deleteAssessment(id): Observable<any> {
    return this.delete('/api/assessments/' + id);
  }

  public launchAssessment(data, id): Observable<any> {
    // return this.put('/api/assessments/' + id, data);
    return this.put('/api/launch_assessment/' + id, data);
  }

  public launchSurvey(data, id): Observable<any> {
    return this.put('/api/survey/' + id, data);
  }

  public getAssessmentById(id): Observable<any> {
    return this.get('/api/assessments/' + id);
  }

  public getAssessmentAttemptById(id): Observable<any> {
    return this.get('/api/assessment-attempts/' + id);
  }

  public createAssessmentAttempt(data): Observable<any> {
    return this.post('/api/assessment-attempts', data);
  }

  public allowAssessmentEdit(id) {
    return this.get('/api/assessments/allow-edit/' + id);
  }

  // Publish Api
  public publishAssessment(id, data): Observable<any> {
    return this.put('/api/assessment-publish/' + id, data);
  }

  // Assessment Excel Download related Api's
  public getDownloadStatusCheck(id) {
    return this.get('/api/activity-status-check/' + id);
  }

  public getDownloadStatusDetails(id) {
    return this.get('/api/get-assessment-attempt-details/' + id);
  }

  public getDownloadScoreDetails(id) {
    return this.get('/api/get-assessment-employee-score/' + id);
  }

  // -----------------------------
  // Labelled Content related APIs
  // -----------------------------
  public getLabelledContents(
    rr: LabelledContentRetrieveRequest = null
  ): Observable<LabelledContent[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      //"lc_title":rr ? rr.title : '',
      ids: rr ? rr.ids : '',
      menu_type: rr ? rr.menu_type : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<LabelledContent[]>(
      '/api/labelled-contents?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateLabelledContent(data, id): Observable<any> {
    return this.putFormData('/api/labelled-contents/' + id, data);
  }

  public createLabelledContent(data): Observable<any> {
    return this.postFormData('/api/labelled-contents', data);
  }

  public deleteLabelledContent(id): Observable<any> {
    return this.delete('/api/labelled-contents/' + id);
  }

  getLabelledContentById(id): Observable<any> {
    return this.get('/api/labelled-contents/' + id);
  }

  // ---------------------------
  // Survey related APIs
  // ----------------------------

  public getSurvey(
    rr: SurveysRetrieveRequest = null
  ): Observable<Surveys[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      // title: rr ? rr.title : '',
      // start_date: rr ? rr.start_date : '',
      // end_date: rr ? rr.end_date : '',
      launched: rr ? rr.launched : '',
      emp_ids: rr ? rr.emp_ids : '',

    };

    return this.get<Surveys[]>(
      '/api/survey?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getSurveyQuestions(survey_id): Observable<Surveys[]> {

    return this.get<Surveys[]>('/api/survey_questions/' + survey_id);
  }

  public createSurvey(data): Observable<any> {
    return this.postFormData('/api/survey', data);
  }

  public updateSurvey(data, id): Observable<any> {
    return this.putFormData('/api/survey/' + id, data);
  }

  public deleteSurvey(id): Observable<any> {
    return this.delete('/api/survey/' + id);
  }

  getSurveyById(id): Observable<any> {
    return this.get('/api/survey/' + id);
  }

  public createSurveyQuestions(data): Observable<any> {
    return this.postFormData('/api/survey_question', data);
  }

  public updateSurveyQuestions(data, id): Observable<any> {
    return this.putFormData('/api/survey_question/' + id, data);
  }

  public attemptSurvey(data): Observable<any> {
    return this.postFormData('/api/survey_attempt', data);
  }

  public createSurveyAttempt(data): Observable<any> {
    return this.postFormData('/api/survey_attempt', data);
  }

  // --------------------------
  // Gap Analysys related APIs
  // ---------------------------

  public getGapAnalysys(
    rr: GapAnalysysRetrieveRequest = null
  ): Observable<GapAnalysys[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
    };

    return this.get<GapAnalysys[]>(
      '/api/competency-gap-analysis?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public createGapAnalysys(data): Observable<any> {
    return this.postFormData('/api/competency-gap-analysis', data);
  }

  public updateGapAnalysys(data, id): Observable<any> {
    return this.putFormData('/api/competency-gap-analysis/' + id, data);
  }

  public deleteGapAnalysys(id): Observable<any> {
    return this.delete('/api/competency-gap-analysis/' + id);
  }

  getGapAnalysysById(id, filter= false): Observable<any> {
    if (filter){
      return this.get('/api/competency-gap-analysis/with_filter/' + id);

    }else{
      return this.get('/api/competency-gap-analysis/' + id);

    }
  }

  startGapAnalysys(id): Observable<any> {
    return this.get('/api/competency-gap-analysis/start/' + id);
  }

  resumeGapAnalysys(id): Observable<any> {
    return this.get('/api/competency-gap-analysis/resume/' + id);
  }

  getGapAnalysysReport(
    rr: GapAnalysysReportRetrieveRequest = null
  ): Observable<any> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      employee_ids: rr ? rr.employee_ids : [],
      level: rr ? rr.level : [],
    };

    // console.log('report log : ', filters);

    return this.get<any>(
      '/api/competency-gap-analysis/view?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  getGapAnalysisExcelReport(
    rr: GapAnalysysReportRetrieveRequest = null
  ): Observable<any> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      employee_ids: rr ? rr.employee_ids : [],
      level: rr ? rr.level : [],
    };

    // console.log('report log : ', filters);

    return this.get<any>(
      '/api/competency-gap-analysis-data-to-generate-excel-report?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  // --------------------------
  // Gap Analysys related APIs End Here
  // ---------------------------

  // ----------------------------------
  // Notification related APIs
  // ---------------------------------

  public startNoticationPolling() {
    this.interval = setInterval(() => {
      this.getNotification();
    }, 10000);
  }

  public getNotification(
    rr: NotificationRetrieveRequest = null): Observable<Notification[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      emp_id: rr ? rr.emp_id : '',
      from_emp_id: rr ? rr.from_emp_id : '',
      is_mob_read: rr ? rr.is_mob_read : '',
      is_app_read: rr ? rr.is_app_read : '',
      app_sent: rr ? rr.app_sent : 'yes',
    };

    return this.get<Notification[]>(
      '/api/notification?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateNotification(data, id): Observable<any> {
    return this.putFormData('/api/notification/' + id, data);
  }


  // ---------------------------------
  // 9 box chart related APIs
  // ---------------------------------

  public getNineBoxChart(
    rr: NineBoxChartRetrieveRequest = null
  ): Observable<NineBoxChart[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      level: rr ? rr.level : '',
      f_year: rr ? rr.f_year : '',
      assessment_ids: rr ? rr.assessment_ids : '',
    };
    return this.get<NineBoxChart[]>(
      '/api/nine_box?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getNineBoxList(
    rr: RetrieveRequest = null
  ): Observable<any> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
    };
    return this.get<NineBoxChart[]>(
      '/api/nine_box_list?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }


  public createNineBox(data) {
    return this.post('/api/nine_box', data);
  }

  public updateNineBox(data, id) {
    return this.put('/api/nine_box/' + id, data);
  }

  public deleteNineBox(id) {
    return this.delete('/api/nine_box/' + id);
  }

  getNineBoxById(id) {
    return this.get('/api/nine_box/' + id);
  }

  getNineboxRoles() {
    return this.get('/api/roles_allowed');
  }

  public publishNineBox(data, id) {
    return this.post('/api/nine_box_publish/' + id, data);
  }

  public getChartById(id:any,type:any) {
    return this.get('/api/nine_box_show/' + id+"/"+type);
  }

  public getChartByIdForExcelGenerate(id:any,type:any) {
    return this.get('/api/nine_box_get_excel_data/' + id+ "/"+type);
  }

  // ---------------------------------
  // Dashboard related APIs
  // ---------------------------------

  public getHybridChart1(
    rr: HybridDrillDownRetrieveRequest = null, assessment_id
  ): Observable<HybridDrillDown[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      group_by: rr ? rr.group_by : '',
      activity_ids: rr ? rr.activity_ids : '',
    };

    return this.get<HybridDrillDown[]>(
      '/api/assessment_dashboard_1/' + assessment_id + '?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getBulletChart1(
    rr: MovingBulletRetrieveRequest = null, assessment_id
  ): Observable<MovingBullet[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      competency: rr ? rr.competency : '',
    };

    return this.get<MovingBullet[]>(
      '/api/assessment_dashboard_2/' + assessment_id + '?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getBulletChart2(
    rr: MovingBulletRetrieveRequest = null, assessment_id
  ): Observable<MovingBullet[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      competency: rr ? rr.competency : '',
    };

    return this.get<MovingBullet[]>(
      '/api/assessment_dashboard_3/' + assessment_id + '?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getPsychometricChart(
    rr: HybridDrillDownRetrieveRequest = null, assessment_id
  ): Observable<HybridDrillDown[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      group_by: rr ? rr.group_by : '',
      activity_ids: rr ? rr.activity_ids : '',
    };

    return this.get<HybridDrillDown[]>(
      '/api/assessment_dashboard_4/' + assessment_id + '?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getHybridChart2(
    rr: HybridDrillDownRetrieveRequest = null, assessment_id
  ): Observable<HybridDrillDown[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      group_by: rr ? rr.group_by : '',
    };

    return this.get<HybridDrillDown[]>(
      '/api/feedback_dashboard_1/' + assessment_id + '?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getHybridChart3(
    rr: HybridDrillDownRetrieveRequest = null, assessment_id
  ): Observable<HybridDrillDown[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      group_by: rr ? rr.group_by : '',
    };

    return this.get<HybridDrillDown[]>(
      '/api/feedback_dashboard_2/' + assessment_id + '?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getHybridChart4(
    rr: HybridDrillDownRetrieveRequest = null, assessment_id
  ): Observable<HybridDrillDown[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      group_by: rr ? rr.group_by : '',
    };

    return this.get<HybridDrillDown[]>(
      '/api/feedback_dashboard_3/' + assessment_id + '?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public checkAvailableActivity(id) {
    return this.get('/api/activity-available-check/' + id);
  }

  // -------------------------------------
  // Perfomance 9 box related APIs
  // -------------------------------------

  public getPerfomanceData(
    rr: PerfomanceDataRetrieveRequest = null
  ): Observable<PerfomanceData[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
    };

    return this.get<PerfomanceData[]>(
      '/api/performance_data?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public createPerfomanceData(data): Observable<any> {
    return this.postFormData('/api/performance_data', data);
  }

  public updatePerfomanceData(data,id): Observable<any> {
    return this.putFormData('/api/performance_data/' + id, data);
  }

  public updatePerfomanceDataBulk(data): Observable<any> {
    return this.putFormData('/api/performance_data/bulk', data);
  }

  public deletePerfomanceData(id, action): Observable<any> {
    return this.delete('/api/performance_data/' + id + '/' + action);
  }

  getPerfomanceDataById(id) {
    return this.get('/api/performance_data/' + id);
  }

  public checkAssociateData(id) {
    return this.get('/api/performance_data_associated/' + id);
  }

  // --------------------------------------
  // Psychomtric Assessment Related APIs
  // --------------------------------------


  public getPsychometricAnalysys(
    rr: PsychometricAssessmentRetrieveRequest = null
  ): Observable<PsychometricAssessment[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      employee_ids: rr ? rr.employee_ids : '',
      assessment_activity_ids: rr ? rr.assessment_activity_ids : '',
      emp_ids: rr ? rr.emp_ids : '',
      competency_ids: rr ? rr.competency_ids : ''
    };

    return this.get<PsychometricAssessment[]>(
      '/api/assessment_activity_psychometric?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getPsychometricAnalysysGroup(
    rr: PsychometricAssessmentGroupRetrieveRequest = null
  ): Observable<PsychometricAssessmentGroup[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      employee_ids: rr ? rr.employee_ids : '',
      assessment_activity_ids: rr ? rr.assessment_activity_ids : '',
      emp_ids: rr ? rr.emp_ids : '',
      competency_ids: rr ? rr.competency_ids : ''
    };

    return this.get<PsychometricAssessmentGroup[]>(
      '/api/assessment_activity_psychometric_group?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public createPsychometricAnalysys(data): Observable<any> {
    return this.postFormData('/api/assessment_activity_psychometric', data);
  }

  public updatePsychometricAnalysys(data, id): Observable<any> {
    // console.log('upda', data, id);
    return this.putFormData('/api/assessment_activity_psychometric/' + id, data);
  }

  public deletePsychometricAnalysys(id): Observable<any> {
    return this.delete('/api/assessment_activity_psychometric/' + id);
  }

  public deletePsychometricAll(id): Observable<any> {
    return this.delete('/api/assessment_activity_psychometric_multiple_delete/' + id);
  }

  getPsychometricById(id) {
    return this.get('/api/assessment_activity_psychometric/' + id);
  }


  // -----------------------------
  // Assessments Activity related APIs
  // -----------------------------

  // getActivityById(id) {
  //   return this.get('/api/assessment-activities/' + id);
  // }
  getActivityByIdWithEmployee(id) {
    return this.get('/api/assessment-activities/' + id);
  }

  getActivityById(id) {
    return this.get('/api/assessment-activity-details/' + id);
  }

  public getActivityQuestionsByActivityId(
    rr: ActivityQuestionsRetrieveRequest = null
  ): Observable<ActivityQuestion[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      is_reattempt: rr ? rr.is_reattempt : '',
      assessment_activity_ids: rr ? rr.assessment_activity_ids : [],
      assessment_activity_attempt_id: rr
        ? rr.assessment_activity_attempt_id
        : null,
      // "name": rr ? rr.name : '',
    };

    return this.get<ActivityQuestion[]>(
      '/api/assessment-activity-questions?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getActivityFeedbackQuestionsByActivityId(
    rr: ActivityFeedbackQuestionsRetrieveRequest = null
  ): Observable<ActivityFeedbackQuestion[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      activity_attempt_feedback_invitation_id: rr
        ? rr.activity_attempt_feedback_invitation_id
        : null,
      assessment_activity_ids: rr ? rr.assessment_activity_ids : [],
      // "name": rr ? rr.name : '',
    };

    return this.get<ActivityFeedbackQuestion[]>(
      '/api/assessment-activity-feedbacks?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getActivityAttemptFeedbackInvitations(
    rr: ActivityAttemptFeedbackInvitationsRetrieveRequest = null
  ): Observable<ActivityAttemptFeedbackInvitation[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',

      assessment_activity_attempt_ids: rr
        ? rr.assessment_activity_attempt_ids
        : [],
      feedback_given_by_id: rr ? rr.feedback_given_by_id : null,
      // "name": rr ? rr.name : '',
    };

    return this.get<ActivityAttemptFeedbackInvitation[]>(
      '/api/activity-attempt-feedback-invitations?f=' +
      btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getAssessmentActivityFeedbackAttempts(
    rr: AssessmentActivityFeedbackAttemptsRetrieveRequest = null
  ): Observable<AssessmentActivityFeedbackAttempt[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',

      activity_attempt_feedback_invitation_id: rr
        ? rr.activity_attempt_feedback_invitation_id
        : [],
      assessment_activity_attempt_id: rr
        ? rr.assessment_activity_attempt_id
        : null,
      // "name": rr ? rr.name : '',
    };

    return this.get<AssessmentActivityFeedbackAttempt[]>(
      '/api/assessment-activity-feedback-attempts?f=' +
      btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getAssessmentActivityFeedbackAttemptsGroup(
    rr: AssessmentActivityFeedbackAttemptsGroupRetrieveRequest = null
  ): Observable<AssessmentActivityFeedbackAttemptGroup[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',

      activity_attempt_feedback_invitation_id: rr
        ? rr.activity_attempt_feedback_invitation_id
        : [],
      assessment_activity_attempt_id: rr
        ? rr.assessment_activity_attempt_id
        : [],
      // "name": rr ? rr.name : '',
    };

    return this.get<AssessmentActivityFeedbackAttemptGroup[]>(
      '/api/assessment-activity-feedback-attempts-group?f=' +
      btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }


  public getAssessmentActivityQuestionAttempts(
    rr: AssessmentActivityQuestionAttemptsRetrieveRequest = null
  ): Observable<AssessmentActivityQuestionAttempt[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',

      assessment_activity_attempt_id: rr
        ? rr.assessment_activity_attempt_id
        : null,
      // "name": rr ? rr.name : '',
    };

    return this.get<AssessmentActivityQuestionAttempt[]>(
      '/api/assessment-activity-question-attempts?f=' +
      btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

// Assessment Question attempt by competency Grouping
  public getAssessmentActivityQuestionAttemptsGroup(
    rr: AssessmentActivityQuestionAttemptsGroupRetrieveRequest = null
  ): Observable<AssessmentActivityQuestionAttemptGroup[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',

      assessment_activity_attempt_id: rr
        ? rr.assessment_activity_attempt_id
        : [],
      // "name": rr ? rr.name : '',
    };

    return this.get<AssessmentActivityQuestionAttemptGroup[]>(
      '/api/assessment-activity-question-attempts-group?f=' +
      btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }


  public getAssessmentActivityAdminViewCounts(
    rr: AssessmentActivityAdminViewCountsRetrieveRequest = null
  ): Observable<AssessmentActivityAdminViewCount[]> {
    let filters = {
      // "page": rr ? rr.page : 1,
      // "per_page": rr ? rr.per_page : 20,
      // "order_by": rr ? rr.order_by : '',
      // "sort_order": rr ? rr.sort_order : 'desc',

      assessment_id: rr ? rr.assessment_id : null,
      // "name": rr ? rr.name : '',
    };

    return this.get<AssessmentActivityAdminViewCountsRetrieveRequest[]>(
      '/api/assessment-activities/admin-view?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  getAssessmentActivityAdminViewCountsByAssessmentId(id): Observable<any> {
    return this.get(
      '/api/assessment-activities/admin-view?assessment-id=' + id
    );
  }

  getAssessmentActivityAdminViewCountsByAssessmentIdByType(id, assessment_type): Observable<any> {
    return this.get(
      '/api/assessment-activities/admin-view?assessment-id=' + id + '&assessment_type=' + assessment_type
    );
  }


  public getActivitiesMappingByActivityId(
    rr: ActivityMappingRetrieveRequest = null
  ): Observable<ActivityMapping[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      assessor_id: rr ? rr.assessor_id : null,
      assessment_activity_ids: rr ? rr.assessment_activity_ids : [],
      participant_id: rr ? rr.participant_id : null,
      emp_id: rr ? rr.emp_id : null,
      // "name": rr ? rr.name : '',
    };

    // return this.get<ActivityMapping[]>('/api/assessment-mappings?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
    return this.get<ActivityMapping[]>(
      '/api/assessment-mappings?pt=yes&f=' + JSON.stringify(filters)
    );
  }

  public deleteNotifications() {
    return this.delete(
      '/api/delete-all-notification');
  }

  public getAssessmentActivityAttemptsByAssessmentAttemptId(
    rr: AssessmentActivityAttemptsRetrieveRequest = null
  ): Observable<AssessmentActivityAttempt[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      status: rr ? rr.status : null,
      assessment_attempt_ids: rr ? rr.assessment_attempt_ids : [],
      assessment_activity_id: rr ? rr.assessment_activity_id : null,
      is_reattempt: rr ? rr.is_reattempt : null,
      // "name": rr ? rr.name : '',
    };

    return this.get<AssessmentActivityAttempt[]>(
      '/api/assessment-activity-attempts?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }


  // public getAssessmentActivity(id
  // ): Observable<any> {

  //   return this.get<AssessmentActivityAttempt[]>(
  //     '/api/assessment-activities/' + id
  //   );
  // }
  public getAssessmentActivity(id
  ): Observable<any> {

    return this.get<AssessmentActivityAttempt[]>(
      '/api/assessment-activity-details/' + id
    );
  }

  public getAssessorAssessmentActivities(assessment_id, assessor_id) {
    return this.get(
      '/api/assessment-activities/assessor?assessment_id=' +
      assessment_id +
      '&assessor_id=' +
      assessor_id
    );
  }

  public getAssessmentActivityFeedbackAttemptsForAssessorView(
    assessment_activity_attempt_id
  ) {
    return this.get(
      '/api/assessment-activity-feedback-attempts/assessor-view/' +
      assessment_activity_attempt_id
    );
  }

  public getScoresForAssessorsView(assessment_activity__id) {
    return this.get(
      '/api/scores/assessor-view?assessment_activity_id=' +
      assessment_activity__id
    );
  }

  public updateAssessmentActivity(data, id): Observable<any> {
    return this.put('/api/assessment-activities/' + id, data);
  }

  public deleteAssessmentActivity(id): Observable<any> {
    return this.delete('/api/assessment-activities/' + id);
  }

  public createAssessmentActivity(data): Observable<any> {
    return this.post('/api/assessment-activities', data);
  }

  public getActivities(
    rr: ActivityRetrieveRequest = null
  ): Observable<Activity[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : '',
      admin_view: rr ? rr.admin_view : '',
      assessment_ids: rr ? rr.assessment_ids : [],
      ids: rr ? rr.ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<Activity[]>(
      '/api/assessment-activities?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  // add activity question
  public createAssessmentQuestions(data): Observable<any> {
    return this.post('/api/assessment-activity-questions', data);
  }

  // update activity question
  public updateAssessmentQuestions(data, id): Observable<any> {
    return this.put('/api/assessment-activity-questions/' + id, data);
  }

  // add activity question
  public createAssessmentQuestionsAV(data): Observable<any> {
    return this.postFormData('/api/assessment-activity-questions', data);
  }

  // update activity question
  public updateAssessmentQuestionsAV(data, id): Observable<any> {
    return this.putFormData('/api/assessment-activity-questions/' + id, data);
  }

  // delete activity question
  public deleteAssessmentQuestion(id): Observable<any> {
    return this.delete('/api/assessment-activity-questions/' + id);
  }

  // delete activity question choice
  public deleteAssessmentQuestionChoice(id): Observable<any> {
    return this.delete('/api/assessment-question-choices/' + id);
  }

  // add activity feedback question
  public createAssessmentFeedbackQuestions(data): Observable<any> {
    return this.post('/api/assessment-activity-feedbacks', data);
  }

  // update activity feedback question
  public updateAssessmentFeedbackQuestions(data, id): Observable<any> {
    return this.put('/api/assessment-activity-feedbacks/' + id, data);
  }

  // delete activity feedback question
  public deleteAssessmentFeedbackQuestion(id): Observable<any> {
    return this.delete('/api/assessment-activity-feedbacks/' + id);
  }

  // add activity mapping data
  public createAssessmentMapping(data): Observable<any> {
    return this.post('/api/assessment-mappings', data);
  }

  // update activity mapping data
  public updateAssessmentMapping(data, id): Observable<any> {
    return this.put('/api/assessment-mappings/' + id, data);
  }

  // delete activity mapping data
  public deleteAssessmentMapping(id): Observable<any> {
    return this.delete('/api/assessment-mappings/' + id);
  }

  // get activity attempt by id
  public getActivityAttemptById(id): Observable<any> {
    return this.get('/api/assessment-activity-attempts/' + id);
  }


  public getSurveyQuestionById(id): Observable<any> {
    return this.get('/api/survey_questions/' + id);
  }

  // update activity attempt status
  public updateActivityAttemptStatus(data, id): Observable<any> {
    return this.put('/api/assessment-activity-attempts/' + id, data);
  }

  public updateActivityAttemptStatusUpdated(data, id): Observable<any> {
    return this.put('/api/assessment-activity-attempts-status-update/' + id, data);
  }

  //update feed back invitation for 360
  public updateFeedBackInvitationStatus(data, id): Observable<any> {
    return this.put('/api/activity-attempt-feedback-invitations/' + id, data);
  }

  //
  public createAssessmentActivityQuestionAttempts(data): Observable<any> {
    return this.post('/api/assessment-activity-question-attempts', data);
  }

  //
  public updateAssessmentActivityQuestionAttempt(data, id) {
    return this.put('/api/assessment-activity-question-attempts/' + id, data);
  }

  //
  public createAssessmentActivityQuestionAttemptsAV(data): Observable<any> {
    return this.postFormData(
      '/api/assessment-activity-question-attempts',
      data
    );
  }

  public createAssessmentActivityFeedbackQuestionAttempts(
    data
  ): Observable<any> {
    return this.post('/api/assessment-activity-feedback-attempts', data);
  }

  //
  public createActivityTimeLog(data): Observable<any> {
    return this.post('/api/assessment-activity-attempt-time-logs', data);
  }

  //
  public updateActivityTimeLog(data, id): Observable<any> {
    return this.put('/api/assessment-activity-attempt-time-logs/' + id, data);
  }

  public updateAssessmentFeedbackQuestionsAttempts(data, id) {
    return this.put('/api/assessment-activity-feedback-attempts/' + id, data);
  }

  public participantsBulkUpload(data): Observable<any> {
    let isformData = true;
    return this.http.post(this.apiEndPoint + '/api/participant-bulk-upload', data, {
      headers: this.getHeaders(isformData),
    });
  }

  public getParticipantsExcel(data) {
    return this.http.post(this.apiEndPoint + '/api/download-excel', data, {
      responseType: 'blob',
      headers: this.getHeaders()
    });
  }


  // -----------------------------
  // maximizer setup > settings related APIs
  // -----------------------------
  public getMaximiserSettings(
    rr: MaximiserSettingsRetrieveRequest = null
  ): Observable<MaximiserSettings[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',

      // "ids": rr ? rr.ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<MaximiserSettings[]>(
      '/api/maximiser-settings?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateMaximiserSettings(data, id): Observable<any> {
    return this.putFormData('/api/maximiser-settings/' + id, data);
  }

  public createMaximiserSettings(data): Observable<any> {
    return this.postFormData('/api/maximiser-settings', data);
  }

  public deleteMaximiserSettings(id): Observable<any> {
    return this.delete('/api/maximiser-setting/' + id);
  }

  public createActivity(data) {
    return this.post('/api/assessment-activities', data);
  }

  public getMaximiserSettingById(id): Observable<any> {
    return this.get('/api/maximiser-settings/' + id);
  }

  // LevelSettings Related APIs

  public updateLevelSettings(data) {
    return this.put('/api/competency-level/' + data.id, data);
  }

  public updateSettings(data) {
    return this.put('/api/settings/' + data.id, data);
  }

  public createSettings(data) {
    return this.post('/api/settings', data);
  }

  public createLevelSettings(data) {
    return this.post('/api/competency-level', data);
  }

  public getLevelSettings() {
    return this.get('/api/competency-level');
  }

  public deleteLevelSettings(id) {
    return this.delete('/api/competency-level/' + id);
  }

  public getLevelById(id) {
    return this.get('/api/competency-level/' + id);
  }

  public behaviourLevelSettings(data) {
    return this.post('/api/behaviour-value-level', data);
  }

  public updatebehaviourLevelSettings(data) {
    return this.put('/api/behaviour-value-level/' + data.id, data);
  }

  public getBehaviourLevelSettings() {
    return this.get('/api/behaviour-value-level');
  }

  public getBehaviourLevelLevelById(id) {
    return this.get('/api/behaviour-value-level/' + id);
  }

  public deleteBehaviourLevelSettings(id) {
    return this.delete('/api/behaviour-value-level/' + id);
  }

  public getMaxPerfomanceScore() {
    let filter = {
      keys: ['performance-max-score']
    };
    return this.get('/api/settings?f=' + btoa(encodeURIComponent(JSON.stringify(filter))));
  }

  public getFinancialYearData() {
    return this.get('/api/performance_data_year');
  }

  // Time zone update API
  public updateTimeZoneSettings(data) {
    return this.put('/api/competency-level/' + data.id, data);
  }

  public createTimeZoneSettings(data) {
    return this.post('/api/competency-level/', data);
  }

  public getTimeZoneSettingsById(id) {
    return this.get('/api/competency-level/' + id);
  }

  public getTimeZones(data) {
    return this.get('/api/time_zone?f=' + btoa(encodeURIComponent(JSON.stringify(data))));
  }

  public getCountryZone(data) {
    return this.get('/api/country_zone?f=' + btoa(encodeURIComponent(JSON.stringify(data))));
  }

  public getTimeZoneById(id) {
    return this.get('/api/time_zone/' + id);
  }

  public getCountryZoneById(id) {
    return this.get('/api/country_zone/' + id);
  }

  public getDefaultTenantTime() {
    let filter = {
      keys: ['time_zone']
    };
    return this.get('/api/settings?f=' + btoa(encodeURIComponent(JSON.stringify(filter))));
  }

  public getMyTime() {
    return this.get('/api/my_time');
  }


  // Country code


  public getSettings(key) {
    let filters = {
      keys: key
    };
    // console.log('filter settings ', filters);
    return this.get<any[]>(
      '/api/settings?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getPublicSettings(key, userName) {
    let filters = {
      keys: key,
      user_name: userName
    };
    return this.get<any[]>(
      '/api/public_settings?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public setPrivacyAndTerms(data) {
    return this.post('/api/click_log', data);
  }

  // Level related settings api ends here
  // -----------------------------
  // news feed APIs
  // -----------------------------
  public getNewsFeeds(
    rr: NewsFeedRetrieveRequest = null
  ): Observable<NewsFeed[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      emp_id: rr ? rr.emp_id : null,
      user_id: rr ? rr.user_id : null,
      feed_type: rr ? rr.feed_type : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<NewsFeed[]>(
      '/api/feeds?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  // -----------------------------
  // news feed Comments APIs
  // -----------------------------
  public getCommentsByFeedId(
    rr: FeedCommentsRetrieveRequest = null
  ): Observable<FeedComment[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',

      feed_entry_ids: rr ? rr.feed_entry_ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<FeedComment[]>(
      '/api/feed-comments?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public createFeedComment(data): Observable<any> {
    return this.post('/api/feed-comments', data);
  }

  // -----------------------------
  // news feed Likes APIs
  // -----------------------------
  public getLikesByFeedId(
    rr: FeedLikesRetrieveRequest = null
  ): Observable<FeedLike[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',

      feed_entry_ids: rr ? rr.feed_entry_ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<FeedLike[]>(
      '/api/feed-likes?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public createFeedLike(data): Observable<any> {
    return this.post('/api/feed-likes', data);
  }

  // -----------------------------
  // Role APIs
  // -----------------------------
  public getRoleObjective(
    rr: RoleObjectiveRetrieveRequest = null
  ): Observable<RoleObjective[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      role_id: rr ? rr.role_id : null,
      // "ids": rr ? rr.ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<RoleObjective[]>(
      '/api/role-objective-groups?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  getRoleObjectiveGroup(id) {
    return this.get('/api/role-objective-groups/' + id);
  }

  public updateRoleObjective(data, id): Observable<any> {
    return this.put('/api/role-objective-groups/' + id, data);
  }

  public createRoleObjective(data): Observable<any> {
    return this.post('/api/role-objective-groups', data);
  }

  public deleteRoleObjective(id): Observable<any> {
    return this.delete('/api/role-objective-groups/' + id);
  }

  public getRoleById(id): Observable<any> {
    return this.get('/api/roles/' + id);
  }

  public getRoleObjectiveGroupsByRoleId(id): Observable<any> {
    return this.get('/api/role-objective-groups/' + id);
  }

  public getRoleObjGrpPropogate(id) {
    return this.get('/api/role-objectives-groups/propogate/' + id);
  }

  public updateRoleObjectiveGroup(data, id): Observable<any> {
    return this.put('/api/role-objective-groups/' + id, data);
  }

  // -----------------------------
  // Manager Role APIs
  // -----------------------------
  public getManagerRoles(
    rr: ManagerRoleRetrieveRequest = null
  ): Observable<ManagerRole[]> {
    let filters = {
      // "page": rr ? rr.page : 1,
      // "per_page": rr ? rr.per_page : 20,
      // "order_by": rr ? rr.order_by : 'id',
      // "sort_order": rr ? rr.sort_order : 'desc',
      role_obj_group_id: rr ? rr.role_obj_group_id : null,
      ap_id: rr ? rr.ap_id : null,
      manager_id: rr ? rr.manager_id : null,
      // "id": rr ? rr.id: null,
      // "ids": rr ? rr.ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<ManagerRole[]>(
      '/api/role-objectives/manager?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  getManagerRole(id) {
    return this.get('/api/role-objectives/manager/' + id);
  }

  // -----------------------------
  // Role SP AP APIs
  // -----------------------------
  public getRoleSpAps(
    rr: RoleSpApRetrieveRequest = null
  ): Observable<RoleSpAp[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',

      // "ids": rr ? rr.ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<RoleSpAp[]>(
      '/api/role-sp-aps?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  getRoleSpAp(id) {
    return this.get('/api/role-sp-aps/' + id);
  }

  public updateRoleSpAp(data, id): Observable<any> {
    return this.put('/api/role-sp-aps/' + id, data);
  }

  public createRoleSpAp(data): Observable<any> {
    return this.post('/api/role-sp-aps', data);
  }

  public deleteRoleSpAp(id): Observable<any> {
    return this.delete('/api/role-sp-aps/' + id);
  }

  public createRoleSpApObjective(data): Observable<any> {
    return this.post('/api/role-objectives', data);
  }

  public updateRoleSpApObjective(data, id): Observable<any> {
    return this.put('/api/role-objectives/' + id, data);
  }

  public deleteRoleSpApObjective(id): Observable<any> {
    return this.delete('/api/role-objectives/' + id);
  }

  // -----------------------------
  // Employee Objective  APIs
  // -----------------------------
  public getEmployeeObjectives(
    rr: EmployeeObjectiveRetrieveRequest = null
  ): Observable<EmployeeObjective[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      emp_id: rr ? rr.emp_id : [],
      // "start_date": rr ? rr.start_date: '',
      // "end_date": rr ? rr.end_date: ''
      // "ids": rr ? rr.ids : '',
      get_active_group: rr ? rr.get_active_group : '',
    };

    return this.get<EmployeeObjective[]>(
      '/api/employee-objectives-groups?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getEmployeeObjectiveGroupsById(id) {
    return this.get('/api/employee-objectives-groups/' + id);
  }

  public updateEmployeeObjective(data, id): Observable<any> {
    return this.put('/api/employee-objectives/' + id, data);
  }

  public updateEmployeeObjectiveGroups(data, id): Observable<any> {
    return this.put('/api/employee-objectives-groups/' + id, data);
  }

  //  360 degree feedback APIS
  public getmanagers(): Observable<Managers[]> {
    return this.get<Managers[]>('/api/employees/manager-list');
  }

  // public create360FeedbackInvite(data): Observable<any> {
  //   return this.post('/api/activity-attempt-feedback-invitations', data);
  // }
  public create360FeedbackInvite(data): Observable<any> {
    return this.post('/api/activity-attempt-feedback-invitations-details', data);
  }

  public get360FeedbackInvite(data): Observable<any> {
    return this.get('/api/activity-attempt-feedback-invitations/' + data);
  }

  public getEmployeeObjectivesForDashboard(id): Observable<any> {
    return this.get('/api/employee-objectives-for-dashboard/' + id);
  }

  // -----------------------------
  // Project APIs
  // -----------------------------
  public getProjects(rr: ProjectRetrieveRequest = null): Observable<Project[]> {
    let filters = {
      // "page": rr ? rr.page : 1,
      // "per_page": rr ? rr.per_page : 20,
      // "order_by": rr ? rr.order_by : 'id',
      // "sort_order": rr ? rr.sort_order : 'desc',
      is_complete: rr ? rr.is_complete : '',
      emp_ids: rr ? rr.emp_ids : null,
      load_subordinate: rr ? rr.load_subordinate : '',
      // "reporting_manager": rr ? rr.reporting_manager: [],
      // "ids": rr ? rr.ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<Project[]>(
      '/api/projects?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getProjectsById(id) {
    return this.get('/api/projects/' + id);
  }

  public createProject(data) {
    return this.post('/api/projects', data);
  }

  public updateProject(data, id) {
    return this.put('/api/projects/' + id, data);
  }

  public deleteProject(id) {
    return this.delete('/api/projects/' + id);
  }

  // -----------------------------
  // Employee Initiatives APIs
  // -----------------------------
  public getEmployeeInitiatives(
    rr: EmployeeInitiativeRetrieveRequest = null
  ): Observable<EmployeeInitiative[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      start_date: rr ? rr.start_date : '',
      // "end_date": rr ? rr.end_date : '',
      emp_id: rr ? rr.emp_id : null,
      // "get_active_group": rr ? rr.get_active_group: '',
      // "name": rr ? rr.name : '',
    };

    return this.get<EmployeeInitiative[]>(
      '/api/employee-initiatives?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public createEmployeeInitiatives(data) {
    return this.post('/api/employee-initiatives', data);
  }

  public getEmployeeInitiativesByEmpId(id) {
    return this.get('/api/employee-initiatives/' + id);
  }

  public updateEmployeeInitiative(data, id) {
    return this.put('/api/employee-initiatives/' + id, data);
  }

  public deleteEmployeeInitiative(id) {
    return this.delete('/api/employee-initiatives/' + id);
  }

  // emloyee-initiative-groups
  // -----------------------------
  // Employee Initiatives  Groups APIs
  // -----------------------------
  public getEmployeeInitiativeGroups(
    rr: EmployeeInitiativeStatusRetrieveRequest = null
  ): Observable<EmployeeInitiativeStatus[]> {
    let filters = {
      // "page": rr ? rr.page : 1,
      // "per_page": rr ? rr.per_page : 20,
      // "order_by": rr ? rr.order_by : 'id',
      // "sort_order": rr ? rr.sort_order : 'desc',
      // "start_date": rr ? rr.start_date : '',
      // "end_date": rr ? rr.end_date : '',
      emp_id: rr ? rr.emp_id : null,
      load_subordinate: rr ? rr.load_subordinate : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<EmployeeInitiativeStatus[]>(
      '/api/employee-initiatives-groups?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getThreeSixtyDegreeFeedbackListing(
    rr: ThreeSixtyDegreeFeedbackListRetrieveRequest
  ) {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      feedback_given_by_id: rr ? rr.feedback_given_by_id : null,
    };
    return this.get<ThreeSixtyDegreeFeedbackList>(
      '/api/activity-attempt-feedback-invitations?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  // -----------------------------
  // Employee Objective  APIs
  // -----------------------------
  public getEmployeeObjectiveGroups(
    rr: EmployeeObjectiveGroupsRetrieveRequest = null
  ): Observable<EmployeeObjectiveGroup[]> {
    let filters = {
      // "page": rr ? rr.page : 1,
      // "per_page": rr ? rr.per_page : 20,
      // "order_by": rr ? rr.order_by : 'id',
      // "sort_order": rr ? rr.sort_order : 'desc',
      // "emp_id": rr ? rr.emp_id : [],
      start_date: rr ? rr.start_date : '',
      end_date: rr ? rr.end_date : '',
      // "ids": rr ? rr.ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<EmployeeObjectiveGroup[]>(
      '/api/employee-objectives-groups/hierarchy?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getEmployeeObjectiveGroupById(id) {
    return this.get('/api/employee-objectives-groups/hierarchy/' + id);
  }

  // -----------------------------
  // Employee Objective  APIs
  // -----------------------------
  public getEmployeeInitiativesGroups(
    rr: EmployeeInitiativesGroupsRetrieveRequest = null
  ): Observable<EmployeeInitiativesGroup[]> {
    let filters = {
      // "page": rr ? rr.page : 1,
      // "per_page": rr ? rr.per_page : 20,
      // "order_by": rr ? rr.order_by : 'id',
      // "sort_order": rr ? rr.sort_order : 'desc',
      emp_id: rr ? rr.emp_id : null,
      month: rr ? rr.month : '',
      year: rr ? rr.year : '',
      emp_initiative_group_id: rr ? rr.emp_initiative_group_id : null,
      // "ids": rr ? rr.ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<EmployeeInitiativesGroup[]>(
      '/api/employee-initiatives-groups/hierarchy?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getEmployeeInitiativesGroupById(id) {
    return this.get('/api/employee-initiatives-groups/' + id);
  }

  public updateEmployeeInitiativesGroup(data, id) {
    return this.put('/api/employee-initiatives-groups/' + id, data);
  }

  // -----------------------------
  // Score  APIs
  // -----------------------------

  // score update
  public updateParticipantScore(data, id): Observable<any> {
    return this.put('/api/scores/' + id, data);
  }

  public getConsensusByActivitiyMappingId(id, assessment_id) {
    return this.get(
      '/api/scores/assessor-view/consensus?activity-mapping-id=' +
      id +
      '?&assessment-id=' +
      assessment_id
    );
  }

  public createConsensusScore(data) {
    return this.post('/api/scores', data);
  }

  public updateConsensusScore(data, id): Observable<any> {
    return this.put('/api/scores/' + id, data);
  }

  public createConsensusLog(data) {
    return this.post('/api/consensus-logs', data);
  }

  public getConsensusLogs(
    rr: ConsensusLogsRetrieveRequest = null
  ): Observable<ConsensusLog[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      activity_mapping_id: rr ? rr.activity_mapping_id : null,
    };

    return this.get<ConsensusLog[]>(
      '/api/consensus-logs?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  // -----------------------------
  // Calender Activity  APIs
  // -----------------------------
  public getCalenderActivitiesByGroup(
    rr: CalenderActivitiesRetrieveRequest = null
  ): Observable<CalenderActivities[]> {
    let filters = {
      // "page": rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 500,
      // "order_by": rr ? rr.order_by : 'id',
      // "sort_order": rr ? rr.sort_order : 'desc',
      emp_ids: rr ? rr.emp_ids : null,
      // "month": rr ? rr.month: '',
      // "year": rr ? rr.year: '',
      // "emp_initiative_group_id": rr ? rr.emp_initiative_group_id: null
      // "ids": rr ? rr.ids : '',
      // "name": rr ? rr.name : '',
    };

    return this.get<CalenderActivities[]>(
      '/api/activities?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateActivities(data, id) {
    return this.put('/api/activities/' + id, data);
  }

  public createActivities(data) {
    return this.post('/api/activities', data);
  }

  public deleteActivity(id) {
    return this.delete('/api/activities/' + id);
  }

  public getCalenderActivities() {
    return this.get('/api/activities');
  }

  // -----------------------------
  // Campaign APIs
  // -----------------------------
  public getCampaigns(
    rr: CampaignsRetrieveRequest = null
  ): Observable<Campaigns[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 500,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      emp_id: rr ? rr.emp_id : null,
      report: rr ? rr.report : null,
      // "name": rr ? rr.name : '',
    };

    return this.get<Campaigns[]>(
      '/api/campaigns?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getCDTxnCount(
    rr: CDTxnCountRetrieveRequest = null
  ): Observable<any[]> {
    let filters = {
      campaign_id: rr ? rr.campaign_id : null,
      from_date: rr ? rr.from_date : '',
      to_date: rr ? rr.to_date : '',
      share_type: rr ? rr.share_type : '',
      level: rr ? rr.level : null,
    };

    return this.get<any[]>(
      '/api/employee-transactions/dashboard/txn-pie?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getCDTxnTrend(
    rr: CDTxnTrendRetrieveRequest = null
  ): Observable<any[]> {
    let filters = {
      campaign_id: rr ? rr.campaign_id : null,
      from_date: rr ? rr.from_date : '',
      to_date: rr ? rr.to_date : '',
      share_type: rr ? rr.share_type : '',
      level: rr ? rr.level : null,
      time_period: rr ? rr.time_period : '',
    };
    // console.log('filter', filters);

    return this.get<any[]>(
      '/api/employee-transactions/dashboard/txn-bar?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getCDTxnTrendXy(
    rr: CDTxnTrendRetrieveRequest = null
  ): Observable<any[]> {
    let filters = {
      campaign_id: rr ? rr.campaign_id : null,
      from_date: rr ? rr.from_date : '',
      to_date: rr ? rr.to_date : '',
      share_type: rr ? rr.share_type : '',
      level: rr ? rr.level : null,
      time_period: rr ? rr.time_period : '',
    };
    // console.log('filter', filters);

    return this.get<any[]>(
      '/api/employee-transactions/dashboard/txn-xy?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getCDTxnTrendWchart(
    rr: CDTxnTrendRetrieveLevelNameRequest = null
  ): Observable<any[]> {
    let filters = {
      campaign_id: rr ? rr.campaign_id : null,
      from_date: rr ? rr.from_date : '',
      to_date: rr ? rr.to_date : '',
      share_type: rr ? rr.share_type : '',
      level: rr ? rr.level : null,
      time_period: rr ? rr.time_period : '',
      level_name: rr ? rr.level_name : '',
    };
    // console.log('filter', filters);

    return this.get<any[]>(
      '/api/employee-transactions/dashboard/txn-wchart?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getCDTxnTrendDchart(
    rr: CDTxnTrendRetrieveFilterRequest = null
  ): Observable<any[]> {
    let filters = {
      campaign_id: rr ? rr.campaign_id : null,
      from_date: rr ? rr.from_date : '',
      to_date: rr ? rr.to_date : '',
      share_type: rr ? rr.share_type : '',
      level: rr ? rr.level : null,
      time_period: rr ? rr.time_period : '',
      filter: rr ? rr.filter : '',
    };
    // console.log('filter', filters);

    return this.get<any[]>(
      '/api/employee-transactions/dashboard/txn-dualchart?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getCDTxnTrendBchart(
    rr: CDTxnTrendRetrieveRequest = null
  ): Observable<any[]> {
    let filters = {
      campaign_id: rr ? rr.campaign_id : null,
      from_date: rr ? rr.from_date : '',
      to_date: rr ? rr.to_date : '',
      share_type: rr ? rr.share_type : '',
      level: rr ? rr.level : null,
      time_period: rr ? rr.time_period : '',
    };
    // console.log('filter', filters);

    return this.get<any[]>(
      '/api/employee-transactions/dashboard/txn-bchart?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getCDParticipationCount(
    rr: CDTxnTrendRetrieveRequest = null
  ): Observable<any[]> {
    let filters = {
      campaign_id: rr ? rr.campaign_id : null,
      from_date: rr ? rr.from_date : '',
      to_date: rr ? rr.to_date : '',
      share_type: rr ? rr.share_type : '',
      level: rr ? rr.level : null,
      time_period: rr ? rr.time_period : '',
    };
    // console.log('filter', filters);

    return this.get<any[]>(
      '/api/employee-transactions/dashboard/txn-participationcount?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getCDEmpParticipation(
    rr: CDEmpParticipationRetrieveRequest = null
  ): Observable<any[]> {
    let filters = {
      campaign_id: rr ? rr.campaign_id : null,
      from_date: rr ? rr.from_date : '',
      to_date: rr ? rr.to_date : '',
      share_type: rr ? rr.share_type : '',
      level: rr ? rr.level : null,
    };

    return this.get<any[]>(
      '/api/employee-transactions/dashboard/txn-pie-2?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getCDTops(rr: CDTopsRetrieveRequest = null): Observable<any[]> {
    let filters = {
      campaign_id: rr ? rr.campaign_id : null,
      from_date: rr ? rr.from_date : '',
      to_date: rr ? rr.to_date : '',
      share_type: rr ? rr.share_type : '',
    };

    return this.get<any[]>(
      '/api/employee-transactions/dashboard/top-participants?f=' +
      btoa(JSON.stringify(filters))
    );
  }

  public getCampaign(id) {
    return this.get('/api/campaigns/' + id);
  }

  public updateCampaign(data, id) {
    return this.put('/api/campaigns/' + id, data);
  }

  public createCampaign(data) {
    return this.post('/api/campaigns', data);
  }

  public deleteCampaign(id) {
    return this.delete('/api/campaigns/' + id);
  }

  public campaignAllowEdit(id) {
    return this.get('/api/campaigns/allow-edit/' + id);
  }

  // -----------------------------
  // Campaign Item Tree Related  APIs
  // -----------------------------
  public getCampaignItemsTree(
    rr: CampaignItemsTreeRetrieveRequest = null
  ): Observable<CampaignItemsTree[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 500,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      // "emp_ids": rr ? rr.emp_ids : null,
      // "id": rr ? rr.id: null,
      // "name": rr ? rr.name : '',
    };

    return this.get<CampaignItemsTree[]>(
      '/api/campaign-items/load-tree?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  // -----------------------------
  // Campaign Item Grid View Related  APIs
  // -----------------------------
  public getCampaignItemGridView(
    rr: CampaignItemGridViewRetrieveRequest = null
  ): Observable<CampaignItemGridView[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      campaign_id: rr ? rr.campaign_id : null,
      txn_counts: rr ? rr.txn_counts : null,
      // "name": rr ? rr.name : '',
    };

    return this.get<CampaignItemGridView[]>(
      '/api/campaign-items?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getCampaignItem(id) {
    return this.get('/api/campaign-items/' + id);
  }

  public updateCampaignItem(data, id) {
    return this.putFormData('/api/campaign-items/' + id, data);
  }

  public createCampaignItem(data) {
    return this.postFormData('/api/campaign-items', data);
  }

  public deleteCampaignItem(id) {
    return this.delete('/api/campaign-items/' + id);
  }

  // -----------------------------
  // Employee Transactions Related  APIs
  // -----------------------------
  public getEmployeeTransactions(
    rr: EmployeeTransactionRetrieveRequest = null
  ): Observable<EmployeeTransaction[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      campaign_id: rr ? rr.campaign_id : null,
      campaign_item_id: rr ? rr.campaign_item_id : null,
      displayer_emp_id: rr ? rr.displayer_emp_id : null,
      observer_emp_id: rr ? rr.observer_emp_id : null,
      pending: rr ? rr.pending : null,
    };

    return this.get<EmployeeTransaction[]>(
      '/api/employee-transactions?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public createEmployeeTransaction(data) {
    return this.post('/api/employee-transactions', data);
  }

  public getEmployeeTransactionById(id) {
    return this.get('/api/employee-transactions/' + id);
  }

  public updateEmployeeTransaction(data, id) {
    return this.put('/api/employee-transactions/' + id, data);
  }

  public deleteEmployeeTransaction(id) {
    return this.delete('/api/employee-transactions/' + id);
  }

  // -----------------------------
  // Video Note related APIs
  // -----------------------------
  public getVideoNotes(
    rr: VideoNotesRetrieveRequest = null
  ): Observable<VideoNote[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      assessment_mapping_id: rr ? rr.assessment_mapping_id : null,
      assessor_id: rr ? rr.assessor_id : null,
    };

    return this.get<VideoNote[]>(
      '/api/video-notes?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateVideoNote(data, id): Observable<any> {
    return this.put('/api/video-notes/' + id, data);
  }

  public createVideoNote(data): Observable<any> {
    return this.post('/api/video-notes', data);
  }

  public deleteVideoNote(id): Observable<any> {
    return this.delete('/api/video-notes/' + id);
  }

  public getVideoNoteById(id) {
    return this.get('/api/video-notes/' + id);
  }

  // -----------------------------
  // Notification Template related APIs
  // -----------------------------
  public getNotificationTemplates(
    rr: NotificationTemplatesRetrieveRequest = null
  ): Observable<NotificationTemplate[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : null,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      is_email_notification_enabled: rr
        ? rr.is_email_notification_enabled
        : null,
      notification_type: rr ? rr.notification_type : null,
      notification_type_id: rr ? rr.notification_type_id : null,
      is_app_notification_enabled: rr
        ? rr.is_app_notification_enabled
        : null,
    };

    return this.get<NotificationTemplate[]>(
      '/api/notification-templates?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getNotificationType(): Observable<any> {
    return this.get('/api/pending-notification-templates');
  }

  public getNotificationEventVariable(data): Observable<any> {
    return this.get('/api/notification-event/' + data);
  }

  public getANotificationType(data): Observable<any> {
    return this.get('/api/notification-type/' + data);
  }

  public updateNotificationTemplate(data, id): Observable<any> {
    return this.put('/api/notification-templates/' + id, data);
  }

  public createNotificationTemplate(data): Observable<any> {
    return this.post('/api/notification-templates', data);
  }

  public deleteNotificationTemplate(id): Observable<any> {
    return this.delete('/api/notification-templates/' + id);
  }

  public getNotificationTemplateById(id) {
    return this.get('/api/notification-templates/' + id);
  }

  // Import Employees
  public importEmployees(data) {
    return this.postFormData('/api/employees/bulk-import', data);
  }

  // Bulk update employees
  public bulkUpdateEmployees(data:any){
    return this.postFormData('/api/employees/import/bulk-update',data)
  }

  // Import Business Unit
  public importBusinessUnit(data) {
    return this.postFormData('/api/business-units/bulk-import', data);
  }

  // Import Department
  public importDepartment(data) {
    return this.postFormData('/api/department/bulk-import', data);
  }

  // Import Function
  public importFunction(data) {
    return this.postFormData('/api/function/bulk-import', data);
  }

  // Import Grade
  public importGrade(data) {
    return this.postFormData('/api/grade/bulk-import', data);
  }

  // Import Location
  public importLocation(data) {
    return this.postFormData('/api/locations/bulk-import', data);
  }

  // Import Role
  public importRole(data) {
    return this.postFormData('/api/role/bulk-import', data);
  }


  //Import Competencies
  public importCompetency(data) {
    return this.postFormData('/api/competencies/bulk-import', data);
  }

  // Export Assessment Activities Attempts Summary

  public createAssessmentActivitiesAttemptsSummary(data): Observable<any> {
    let url = '/api/assessment-activity-attempts/summary';
    let headers = new HttpHeaders();
      // .set('X-Requested-With', 'XMLHttpRequest')
      // .set('Access-Control-Allow-Origin', '*');
    // headers = headers.set('Accept', 'text/csv');
    // headers = headers.set('responseType', 'text/csv');
    headers = headers.set(
      'Authorization',
      'Bearer ' + sessionStorage.getItem('accountAccessToken')
    );
    return this.http.post(this.apiEndPoint + url, data, {
      headers: headers,
      responseType: 'text',
    });
  }

  public reinforcementExcelDownload(id) {
    return this.http.get(this.apiEndPoint + '/api/learning-reinforcement-excel/' + id, {
      responseType: 'blob',
      headers: this.getHeaders()
    });
  }

  public getEmployeeCSVSampleTemplateDoc(
    rr: EmployeeCSVSampleTemplateDocRetrieveRequest = null
  ): Observable<EmployeeCSVSampleTemplateDoc[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      caption: rr ? rr.caption : '',
    };

    return this.get<EmployeeCSVSampleTemplateDoc[]>(
      '/api/documents?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }


  public getAllActivity(): Observable<any> {
    return this.get<any>('/api/show_all_activity');
    // return this.http.get<Observable<any>>('https://jsonplaceholder.typicode.com/todos/', {
    //   headers: this.getHeaders()
    // });
  }

  public getUserActivity(): Observable<any> {
    return this.get<any>('/api/show_my_activity');
  }

  public getSelectedUserActivity(num): Observable<any> {
    return this.get<any>('/api/show_my_activity/' + num);
  }

  public getUsersList(): Observable<any> {
    return this.get<any>('/api/get_all_users');
  }

  getErrorOnGapAnalysys(id): Observable<any> {
    return this.get('/api/competency-gap-analysis/error/' + id);
  }


  // PII Data  Related API
  public storePiiData() {
    let headers = this.getHeaders();
    headers = headers.set('Content-Type', 'application/json');
    // console.log('headers', headers);
    return this.put('/api/pii_store', {headers: headers});
  }

  public getPiiOtp() {
    let headers = this.getHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.put('/api/pii_otp', {headers: headers});
  }

  public verifyPiiOtp(otp) {
    let data = {
      'otp': otp
    };
    let headers = this.getHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post(this.apiEndPoint + '/api/check_ppi_otp', JSON.stringify(data), {headers});

  }

  public getEmployeePiiData() {
    return this.get('/api/pii_data_request');
  }

// public getAllPiiRequests(){
//   return this.get('/api/get_all_pii_request')
// }

  public set_pii_anonymize(user_id, action) {
    const data = {
      user_id: user_id,
      action: action
    };
    return this.put('/api/pii_anonymize', JSON.stringify(data));
  }

  public getPiiPresentByEmployee(
    rr: EmployeePiiRetrieveRequest = null
  ): Observable<EmployeePii[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 500,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      admin_approved: rr ? rr.admin_approved : '',
      emp_ids: rr ? rr.emp_ids : '',

    };
    return this.get<EmployeePii[]>(
      '/api/get_all_pii_request?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

// new dashboard code

  public Reinforcement_status(
    rr: ReinforcementAssessmentStatusRetrieveRequest = null, assessment_id
  ): Observable<ReinforcementAssessmentStatus[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      group_by: rr ? rr.group_by : '',
    };
    // reinforcement_dashboard_1

    return this.get<ReinforcementAssessmentStatus[]>(
      '/api/reinforcement_dashboard_1/' + assessment_id + '?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public reinforcementLeaderBoard(id) {
    return this.get('/api/reinforcement_dashboard_3/' + id);
  }

  public reinforcementLeaderBoardEachActivity(id) {
    return this.get('/api/reinforcement_dashboard_2/' + id);
  }

  public getheatMapofEachActivity(id) {
    return this.get('/api/reinforcement_heatmap/' + id);
  }

  public getLrDasboardCards(id) {
    return this.get('/api/reinforcement_dashboard_get_cards/' + id);
  }


//End of PII Data Request

  public getOverallScoreReport(
    rr: getOverallOfReportRetriveRequest = null
  ): Observable<any> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      assessment_activity_attempt_ids_la: rr ? rr.assessment_activity_attempt_ids_la : '',
      assessment_activity_attempt_ids_360: rr ? rr.assessment_activity_attempt_ids_360 : '',
      assessment_activity_ids: rr ? rr.assessment_activity_ids : '',

    };

    return this.get<any>(
      '/api/assessment_activity_overall?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );


  }

  duptoken;

  set dupToken(val) {
    this.duptoken = val;
  }

  public refresh_token() {

    let tokenExpiry = sessionStorage.getItem('tokenexpiry');
    let accountAccessToken = sessionStorage.getItem('accountAccessToken');

    let duptokenExpiry = localStorage.getItem('duptokenexpiry');
    let dupaccountAccessToken = localStorage.getItem('dupaccountAccessToken');

    // this.dupToken(accountAccessToken)

    if (tokenExpiry == null || tokenExpiry == undefined || tokenExpiry == 'undefined' || accountAccessToken == null || accountAccessToken == undefined || accountAccessToken == 'undefined') {
      // console.log('token expired');
      if (duptokenExpiry == null || duptokenExpiry == undefined || duptokenExpiry == 'undefined' || dupaccountAccessToken == null || dupaccountAccessToken == undefined || dupaccountAccessToken == 'undefined') {
        this.navigateTopreLogin();
        console.log('token expired1');
        // this.router.navigate(['/prelogin']);
      } else {
        tokenExpiry = duptokenExpiry;
        accountAccessToken = dupaccountAccessToken;
        // console.log('dup token valid');
      }
    } else {
      // console.log('token valid');
    }
    // console.log('dup',tokenExpiry);
    // console.log('dup1',accountAccessToken);
    let expiry_date_string = tokenExpiry + '+0530';
    let expiry_date = new Date(expiry_date_string);
    let minutesToAdd = 2;
    let current_date = new Date();
    var check_expiry_date = new Date(expiry_date.getTime() - minutesToAdd * 60000);
    // let latest_date =this.datepipe.transform(current_date, 'yyyy-MM-dd');
    // console.log("exp",expiry_date_string)
    // console.log("latest",current_date)
    // console.log("checkdate",check_expiry_date)
    if (current_date > check_expiry_date) {
      // console.log('token expired');
      let body = {
        'token': accountAccessToken,
        'login_from': 'web'
      };
      // console.log('body', body);
      this.http.post<any>(`${this.apiEndPoint}/api/verify_token/`, body)
        .subscribe((data) => {
            // console.log(data);
            if (data.status == 'success') {
              this.ngZone.run(() => {
                sessionStorage.setItem('accountAccessToken', data['token']);
                sessionStorage.setItem('tokenexpiry', data['token_expiry']);

                localStorage.setItem('dupaccountAccessToken', data['token']);
                localStorage.setItem('duptokenexpiry', data['token_expiry']);
                this.getLoggedInUserDetail().subscribe(res => {
                  sessionStorage.setItem('me', btoa(JSON.stringify(res)));
                  localStorage.setItem('dupme', btoa(JSON.stringify(res)));
                  // this.router.navigate(['/']);
                });
              });
            } else if (data.status == 'norefresh') {
              sessionStorage.setItem('tokenexpiry', data['expiry']);
              localStorage.setItem('duptokenexpiry', data['expiry']);
            }
          },
          (err) => {
            this.navigateTopreLogin();
          }
        );
    }
  }

  navigateTopreLogin() {
    console.log('token expired12');
    sessionStorage.removeItem('me');
    sessionStorage.removeItem('dupme');
    sessionStorage.removeItem('accountAccessToken');
    sessionStorage.removeItem('dupaccountAccessToken');
    window.sessionStorage.clear();
    this.router.navigate(['/prelogin']);
  }


//reCAPTCHA
  public recaptchaVerification(token) {
    let data = {
      'token': token
    };
    return this.post('/api/recaptcha_verification', JSON.stringify(data));
  }

// Publish View 9 Box
  public get9BoxPublishList() {
    return this.get('/api/nine_box_publish_list');
  }

  public get9BoxChartView(id) {
    return this.get('/api/nine_box_publish_view/' + id);
  }

  public get9BoxChartViewForExcelGenerate(id) {
    return this.get('/api/nine_box_publish_view_get_excel_data/' + id);
  }

//Gap Analysis Publish Role
  getGapAnalysisRoles() {
    return this.get('/api/roles_allowed');
  }

  public publishGapAnalysis(data, id) {
    return this.post('/api/gap_analysis_publish/' + id, data);
  }

  public GapAnalysisPublishIds() {
    return this.get('/api/gap_publish_list');
  }

  public getGapAnalysysByFilter(
    rr: GapAnalysysByFilterRetrieveRequest = null
  ): Observable<GapAnalysysByFilter[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      emp_id: rr ? rr.emp_id : null
    };

    return this.get<GapAnalysysByFilter[]>(
      '/api/gap-analysis-by-filter?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getNineBox(filters) {
    return this.get('/api/nine_box?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public Sub_emp_ids() {
    return this.get('/api/gap_publish_list_sub_emp_ids');
  }

  public getMyGapAnalysisParticipation() {
    return this.get('/api/gap-analysis-my-participation');
  }

  public getMyNineBoxParticipation() {
    return this.get('/api/nine_box_list_participant');
  }

  public getPublishedNineBox() {
    return this.get('/api/nine_box_list_published');
  }

  public getPublishedGapAnalysis() {
    return this.get('/api/gap_analysis_list_published');
  }

  public get_emp_of_gap_analysis_filter(filters) {
    return this.get('/api/get_emp_of_gap_analysis_filter?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }


  public getMyNineBoxParticipationView(id) {
    return this.get('/api/nine_box_participant_view/' + id);
  }

  public getMyNineBoxParticipationForPublishedView(id) {
    return this.get('/api/nine_box_participant_published_view/' + id);
  }

  public getMyNineBoxParticipationDataForExcelGenerate(id) {
    return this.get('/api/nine_box_participant_view_get_excel_data/' + id);
  }

  public getMyNineBoxPublishedDataForExcelGenerate(id) {
    return this.get('/api/nine_box_published_view_get_excel_data/' + id);
  }


  //API Call For 360 Feedback Spider Chart
  public get360SpiderChart(id) {
    return this.get('/api/feedback_dashboard_4/' + id);
  }

  //API CALL For Gap Anlysis Spider Chart

  getGapAnalysysReportSpiderChart(
    rr: GapAnalysysReportRetrieveRequest = null
  ): Observable<any> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      superset: rr ?  rr.superset : [],
      employee_ids: rr ? rr.employee_ids : [],
      level: rr ? rr.level : [],
    };

    // console.log('spider log : ', filters);

    return this.get<any>(
      '/api/competency_dashboard_1?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  // API Call For FeedbackDashbaord by Assessment id and Filter
  public get360NameandRole(id) {
    return this.get('/api/feedback_dashboard_assessment/' + id);
  }

  public getFeedbackDaskhboard(
    rr: HybridDrillDownRetrieveRequest = null, activity_id
  ): Observable<HybridDrillDown[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      group_by: rr ? rr.group_by : '',
      type: rr ? rr.type : '',
    };

    return this.get<HybridDrillDown[]>(
      '/api/feedback_dashboard_activity/' + activity_id + '?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getAssessmentDashboardCard(id) {
    return this.get('/api/dashboard_cards/' + id);
  }

  //API CALL FOR 360 Feedback Table
  public get360FeedbackTable(id) {
    return this.get('/api/feedback_table/' + id);
  }

  //API call for level change questiom delete

  public levelChangesAction(id) {
    return this.delete('/api/delete-questions-by-activity/' + id);
  }

  public levelChangesFeedback(id) {
    return this.delete('/api/delete-feedback-by-activity/' + id);
  }

  public getAssessmentReportByAssessmentAttemptId(
    rr: AssessmentActivityAttemptsRetrieveRequest = null
  ) {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      status: rr ? rr.status : null,
      assessment_attempt_ids: rr ? rr.assessment_attempt_ids : [],
      assessment_activity_id: rr ? rr.assessment_activity_id : null,
      is_reattempt: rr ? rr.is_reattempt : null,
      // "name": rr ? rr.name : '',
    };
    return this.http.get(this.apiEndPoint + '/api/assessment-report?f=' + btoa(encodeURIComponent(JSON.stringify(filters))), {
      responseType: 'blob',
      headers: this.getHeaders()
    });

    // return this.get<AssessmentActivityAttempt[]>(
    //   '/api/assessment-report?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    // );
  }

  public saveCategory(data) {
    return this.post('/api/assessment_category', data);
  }

  public updateCategory(id, data) {
    return this.put('/api/assessment_category/' + id, data);
  }

  public getAllCategories(type) {
    return this.get('/api/assessment_category/' + type);
  }

  public deleteCategory(id) {
    return this.delete('/api/assessment_category/' + id);
  }

  public savePrematureClosureandDeadline(data) {
    return this.post('/api/activity_category_log', data);
  }

  public getAssessmentCategoryData(id, type) {
    return this.get('/api/activity_category_log/' + id + '/' + type);
  }

  //Activity Enrolled or Not Status API

  public getEnrolledorNotData(id) {
    return this.get('/api/assessment-activities/enrolled-status/' + id);
  }

  //API Related to Premium_360

  //API Call For Return All Templates Data
  public getPremium360TemplateList(
    rr: Premium_360RetrieveRequest = null
  ): Observable<Premium_360[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      status: rr ? rr.status : null
    };

    return this.get<Premium_360[]>(
      '/api/premium_360?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  //Api Call for Save Template Data
  public savePremium360Template(data) {
    return this.post('/api/premium_360', data);
  }

  //Api Call for Delete Template by Id
  public deletePremium360Template(id) {
    return this.delete('/api/premium_360/' + id);
  }

  //Api Call for get Template by Id
  public getPremium360Template(id) {
    return this.get('/api/premium_360/' + id);
  }

  //Api Call For Update Template By id

  public editPremium360Template(data, id) {
    return this.put('/api/premium_360/' + id, data);
  }

  //Adding premium 360 Question add
  public createPremium360Question(data): Observable<any> {
    return this.post('/api/premium_360_template_question', data);
  }


  public getPremium360QuestionByTemplateId(
    rr: Premium_360_QuestionsRetrieveRequest = null
  ): Observable<Premium_360_Questions[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 500,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      template_id: rr
        ? rr.template_id
        : null,
    };

    return this.get<Premium_360_Questions[]>(
      '/api/premium_360_template_question?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  //Api Call for Delete Template Question by Id
  public deletePremium360TemplateQuestionById(id) {
    return this.delete('/api/premium_360_template_question/' + id);
  }

  // Api for update premium 360 Question by id
  public updatePremium360TemplateQuestionById(data, id): Observable<any> {
    return this.put('/api/premium_360_template_question/' + id, data);
  }

  //Api call for add selected activity template details

  public savePremium360ActivityTemplateData(data) {
    return this.post('/api/premium_360_activity', data);
  }

  //Api Call for get Template Activity Data by Activity Id
  public getPremium360ActivityTemplateDatabyActivityId(id) {
    return this.get('/api/premium_360_activity_by_activity_id/' + id);
  }

  //Api call for add selected activity template details

  public updatePremium360ActivityTemplateData(data, id): Observable<any> {
    return this.put('/api/premium_360_activity/' + id, data);
  }

  //Api Call for check the template is used in any activity Id
  public getPremium360ActivityTemplateUsedCheck(id) {
    return this.get('/api/premium_360_used/' + id);
  }

  //Api For save the premium_360 attempt

  public savePremium360AttemptData(action, data) {
    return this.post('/api/premium_360_attempt/' + action, data);
  }

  //Api Call for get the attempt data by activity_attempt_id
  public getPremium360AttemptData(id) {
    return this.get('/api/premium_360_attempt/' + id);
  }

  //Api Call for get the attempt data by activity_attempt_id for admin view
  public getPremium360AttemptAdminView(id) {
    return this.get('/api/premium_360_attempt_all/' + id);
  }

  //API For Copy a Template
  public copyPremium360Template(id) {
    return this.post('/api/premium_360_copy/' + id, '');
  }

  //API get Save and exit attempt data

  public getPremium360SaveandExitData(id) {
    return this.get('/api/premium_360_attempt_save/' + id);
  }

  //Api For Premium 360 activity status

  public getPremium360ActivityStatus(id) {
    return this.get('/api/premium_360_attempt_save_first/' + id);
  }


  public saveBulkDataForFBInvitation(data): Observable<any> {
    return this.post('/api/save-bulk-invitation-data', data);
  }


  // -----------------------------
  // Tags related APIs
  // -----------------------------
  public getAllTags(
    rr: TagsRetrieveRequest = null
  ): Observable<Tags[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
    };
    return this.get<Tags[]>(
      '/api/tags?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public updateTags(data, id): Observable<any> {
    return this.put('/api/tags/' + id, data);
  }

  public createTags(data): Observable<any> {
    return this.post('/api/tags', data);
  }

  public deleteTags(id): Observable<any> {
    return this.delete('/api/tags/' + id);
  }

  // -----------------------------
  // File Upload related APIs
  // -----------------------------

  public questionFilesUpload(id, data): Observable<any> {
    let isformData = true;
    return this.http.post(this.apiEndPoint + '/api/assessment-question-choices-upload-materials/' + id, data, {
      headers: this.getHeaders(isformData),
    });
  }

  public deleteUploadFiles(id): Observable<any> {
    let isformData = true;
    return this.http.post(this.apiEndPoint + '/api/assessment-question-choices-upload-materials/' + id, {
      headers: this.getHeaders(isformData),
    });
  }

  // -----------------------------
  // Leaderboard related APIs
  // -----------------------------
  public getLeaderboard(id): Observable<any> {
    return this.get('/api/get-leaderboard/' + id);
  }

  public updateTotalScore(data): Observable<any> {
    return this.put('/api/update-activity-attempt-score', data);
  }

  public getLeaderboardDashboard(id): Observable<any> {
    return this.get('/api/get-assessment-leader-board/' + id);
  }

  // -----------------------------
  // HeatMap related APIs
  // -----------------------------
  // public getHeatMap(id): Observable<any> {
  //   return this.get('/api/get-heatmap-reinforcement/' + id);
  // }

  public getHeatMap(
    rr: HeatMapRetrieveRequest = null
  ): Observable<HeatMaps[]> {
    let filters = {
      activity_ids: rr ? rr.activity_ids : [],
      key: rr ? rr.key : '',
    };
    return this.get<HeatMaps[]>(
      '/api/get-heatmap-reinforcement?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getHeatMapC(
    rr: HeatMapRetrieveRequest = null
  ): Observable<HeatMaps[]> {
    let filters = {
      activity_ids: rr ? rr.activity_ids : [],
      key: rr ? rr.key : '',
    };
    return this.get<HeatMaps[]>(
      '/api/get-heatmap-reinforcement-competency?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getAllActivityForHeatMap(type): Observable<any> {
    return this.get('/api/get-all-reinforcement-activities/' + type);
  }

  public getAllActivityTags(id): Observable<any> {
    return this.get('/api/tags-for-activity/' + id);
  }

  // -----------------------------
  // Spacing related APIs
  // -----------------------------
  public calculateSpacing(id, val): Observable<any> {
    return this.post('/api/get-time-data/' + id, val);
  }

  public addSpacing(id): Observable<any> {
    return this.get('/api/add-spacing/' + id);
  }

  // -----------------------------
  // Re-Attempt related APIs
  // -----------------------------
  public getReattemptQuestions(id): Observable<any> {
    return this.get('/api/get-reinforcement-activity-questions/' + id);
  }

  public reAttemptDetails(val): Observable<any> {
    return this.post('/api/reattempt-details', val);
  }

  // -----------------------------
  // BBS related APIs
  // -----------------------------

  public createBehaviourValues(val): Observable<any> {
    return this.post('/api/behaviour-values', val);
  }

  public updateBehaviourValues(data, id): Observable<any> {
    return this.put('/api/behaviour-values/' + id, data);
  }

  public getAllBehaviour(
    rr: BehaviourRetrieveRequest = null
  ): Observable<Behaviour[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      behaviour_name: rr ? rr.behaviour_name : '',
      level_id: rr ? rr.level_id : '',
      parent_id: rr ? rr.parent_id : '',
    };

    return this.get<Behaviour[]>(
      '/api/behaviour-values?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public deleteBehaviour(id): Observable<any> {
    return this.delete('/api/behaviour-values/' + id);
  }

  public behaviourBulkUpload(frmData): Observable<any> {
    return this.postFormData('/api/behaviour-value/bulk-import', frmData);
  }


  // -----------------------------
  // Leaderboard related APIs
  // -----------------------------

  public getActivityReport(id): Observable<any> {
    return this.get('/api/assessment-completed/' + id);
  }


  // -----------------------------
  // Lead Indications related APIs
  // -----------------------------

  public createLeadIndicator(val): Observable<any> {
    return this.post('/api/lead-indicators', val);
  }

  public getOneLeadIndicator(id): Observable<any> {
    return this.get('/api/lead-indicators/' + id);
  }

  public updateLeadIndicator(data, id): Observable<any> {
    return this.put('/api/lead-indicators/' + id, data);
  }

  public deleteLeadIndicator(id): Observable<any> {
    return this.delete('/api/lead-indicators/' + id);
  }

  public getAllLeadIndicator(): Observable<any> {
    return this.get('/api/lead-indicators');
  }

  // -----------------------------
  // lag Indications related APIs
  // -----------------------------

  public createLagIndicator(val): Observable<any> {
    return this.post('/api/lag-indicators', val);
  }

  public getOneLagIndicator(id): Observable<any> {
    return this.get('/api/lag-indicators/' + id);
  }

  public updateLagIndicator(data, id): Observable<any> {
    return this.put('/api/lag-indicators/' + id, data);
  }

  public deleteLagIndicator(id): Observable<any> {
    return this.delete('/api/lag-indicators/' + id);
  }

  public getAllLagIndicator(): Observable<any> {
    return this.get('/api/lag-indicators');
  }


  // -----------------------------
  // Current Indications related APIs
  // -----------------------------

  public createCurrentIndicator(val): Observable<any> {
    return this.post('/api/current-indicators', val);
  }

  public getOneCurrentIndicator(id): Observable<any> {
    return this.get('/api/current-indicators/' + id);
  }

  public updateCurrentIndicator(data, id): Observable<any> {
    return this.put('/api/current-indicators/' + id, data);
  }

  public deleteCurrentIndicator(id): Observable<any> {
    return this.delete('/api/current-indicators/' + id);
  }

  public getAllCurrentIndicator(): Observable<any> {
    return this.get('/api/current-indicators');
  }

  // -----------------------------
  // Features related APIs
  // -----------------------------

  public createFeatures(val): Observable<any> {
    return this.post('/api/features', val);
  }

  public getOneFeatures(id): Observable<any> {
    return this.get('/api/features/' + id);
  }

  public updateFeatures(data, id): Observable<any> {
    return this.put('/api/features/' + id, data);
  }

  public deleteFeatures(id): Observable<any> {
    return this.delete('/api/features/' + id);
  }

  public getAllFeatures(): Observable<any> {
    return this.get('/api/features');
  }


  // -----------------------------
  // TelantCard related APIs
  // -----------------------------

  public createTelantCard(val): Observable<any> {
    return this.post('/api/talent-card', val);
  }

  public getTelantCard(): Observable<any> {
    return this.get('/api/talent-card');
  }

  // public getAllTelantCard(): Observable<any> {
  //   return this.get('/api/all-talent-card');
  // }
  public updateTelantCard(data, id): Observable<any> {
    return this.put('/api/talent-card/' + id, data);
  }

  public getTelantCardStatus(): Observable<any> {
    return this.get('/api/talent-card-status');
  }

  public postTelantCardStatus(val): Observable<any> {
    return this.post('/api/talent-card-status', val);
  }

  public getAllTelantCard(
    rr: TalentcardRetrieveRequest = null
  ): Observable<any> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      emp_ids: rr ? rr.emp_ids : '',
      display_name: rr ? rr.display_name : '',
      reporting_manager: rr ? rr.reporting_manager : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      location_ids: rr ? rr.location_ids : '',
      department_ids: rr ? rr.department_ids : '',
      role_ids: rr ? rr.role_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      employee_full_search: rr ? rr.employee_full_search : '',

    };
    return this.get(
      '/api/all-talent-card?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  // ---------------------Assessment Publish-------

  public getPublishRoles() {
    return this.get('/api/roles_allowed');
  }

// post api for assessment report publish

  public assessmentReportPublish(data): Observable<any> {
    return this.post('/api/assessment-report-publish', data);
  }

  // get api for assessment report publish
  public getAssessmentReportPublish(id) {
    return this.get('/api/assessment-report-publish/' + id);
  }

  // get assesssment list report publish

  public getReportPublishList(
    rr: RetrieveRequest = null
  ): Observable<any> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 20,
      order_by: rr ? rr.order_by : 'id',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
    };
    return this.get(
      '/api/get-assessment-for-report-publish?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getAssessmentReportPublishById(id) {
    return this.get('/api/assessment-report-publish-list/' + id);
  }

  public assessmentReportPublishUpdate(data, id): Observable<any> {
    return this.put('/api/assessment-report-publish/' + id, data);
  }

  public getNineBoxPublishById(id): Observable<any> {
    return this.get('/api/nine_box_publish/' + id);
  }

  public publishNineBoxUpdate(data, id) {
    return this.put('/api/nine_box_publish/' + id, data);
  }

  public getPublishGapAnalysisById(id) {
    return this.get('/api/gap_analysis_publish/' + id);
  }

  public publishGapAnalysisUpdate(data, id) {
    return this.put('/api/gap_analysis_publish/' + id, data);
  }

  public getTransactionReport(data) {
    return this.http.post(this.apiEndPoint + '/api/campaign_exel_download', data, {
      responseType: 'blob',
      headers: this.getHeaders()
    });
  }

  // -----------------------------
  // Report related APIs
  // -----------------------------

  public getQuick360Report() {
    return this.get('/api/report-menu-quick360');
  }

  public getQuick360ReportExcel(id) {
    return this.http.get(this.apiEndPoint + '/api/premium_360_excel_download/' + id, {
      responseType: 'blob',
      headers: this.getHeaders()
    });
  }

  public getLeftMenuData() {
    return this.get('/api/get-navigation-web');
  }

  // -----------------------------
  // Quick 360 dashboard Related  APIs
  // -----------------------------

  public getQuick360DashboardCard(id) {
    return this.get('/api/dashboard_cards_quick_360/' + id);
  }

  public getQuick360DashboardCardsUser(id) {
    return this.get('/api/dashboard_cards_quick_360_user/' + id);
  }

  public getBaloonDataDashboard(id) {
    return this.get('/api/attempt_no_dashboard/' + id);
  }

  // -----------------------------
  // LR Enhancement Related  APIs
  // -----------------------------

  public createLR_Enhancement(data): Observable<any> {
    return this.post('/api/lr_employee_level', data);
  }

  public updateLR_Enhancement(data, id) {
    return this.put('/api/lr_employee_level/' + id, data);
  }

  public getAllEnhancement() {
    return this.get('/api/lr_employee_level');
  }

  public deleteEnhancementLevel(id): Observable<any> {
    return this.delete('/api/lr_employee_level/' + id);
  }

  public getAllEnhancementActivity() {
    return this.get('/api/learning_activity_list');
  }

  public getCompetencyForActivity(data): Observable<any> {
    return this.post('/api/competency_list_from_activity', data);
  }

  public getEmployeeForLevel(data): Observable<any> {
    return this.post('/api/get_employee_level', data);
  }

  public getHeadsOfParticipants(data) {
    return this.get('/api/get-heads-of-employee?f=' + encodeURIComponent(btoa(JSON.stringify(data))));
  }

  public getHeadsOfNineBoxParticipants(data) {
    return this.get('/api/get-heads-ofnineBox-employee?f=' + encodeURIComponent(btoa(JSON.stringify(data))));
  }

  public getHeadsOfGapAnalysisParticipants(data) {
    return this.get('/api/get_heads_of_gap_analysis_employee?f=' + encodeURIComponent(btoa(JSON.stringify(data))));
  }


  public getAllleaderboard(data) {
    return this.post('/api/leaderboard_app_master_test', data);
  }


// Added for Form Module by Surajit Pramanik
  public getFields(tblName) {
    return this.get(`/api/options/columns/${tblName}`);
  }

  public getOptionsTable(tblName, valueKey) {
    return this.get(`/api/options/table/${tblName}/${valueKey}`);
  }

  public getOptionsApi(apiUrl) {
    return this.get(apiUrl);
  }

  // forms
  public createForm(data): Observable<any> {
    return this.post('/api/forms', JSON.stringify(data));
  }

  public getForms(rr: FormRetrieveRequest = null): Observable<Form[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      name: rr ? rr.name : '',
    };
    return this.get<Form[]>('/api/forms?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public getFormById(id) {
    return this.get(`/api/forms/${id}`);
  }

  public updateForm(data, id): Observable<any> {
    return this.put(`/api/forms/${id}`, JSON.stringify(data));
  }

  public updateStatus(data, id): Observable<any> {
    return this.put(`/api/forms/status/${id}`, JSON.stringify(data));
  }

  public deleteForm(id): Observable<any> {
    return this.delete(`/api/forms/${id}`);
  }


  public saveFormSubmission(data): Observable<any> {
    return this.post('/api/forms/submissions', JSON.stringify(data));
  }

  public updateFormSubmission(id, data): Observable<any> {
    return this.put(`/api/forms/submissions/${id}`, JSON.stringify(data));
  }

  public getSubmissionsByFormId(formId): Observable<any> {
    return this.get(`/api/forms/${formId}/submissions`);
  }

  public getSubmissionsByLaunchId(launchId): Observable<any> {
    return this.get(`/api/form-launches/${launchId}/submissions`);
  }

  public getTeamSubmissionsByFormId(formId): Observable<any> {
    return this.get(`/api/forms/${formId}/team-submissions`);
  }

  public getTeamSubmissionsByLaunchId(launchId): Observable<any> {
    return this.get(`/api/form-launches/${launchId}/team-submissions`);
  }

  public getMySubmissionByLaunchId(launchId): Observable<any> {
    return this.get(`/api/form-launches/${launchId}/my-submissions`);
  }

  public getMySubmissionByFormId(formId): Observable<any> {
    return this.get(`/api/forms/${formId}/my-submissions`);
  }

  public getSubmissionById(id): Observable<any> {
    return this.get(`/api/forms/submissions/${id}`);
  }

  // form launches
  public createFormLaunch(data): Observable<any> {
    return this.post('/api/form-launches', JSON.stringify(data));
  }

  public getFormLaunches(rr: FormLaunchRetrieveRequest = null): Observable<FormLaunch[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      title: rr ? rr.title : '',
    };
    return this.get<FormLaunch[]>('/api/form-launches?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public getMyFormLaunches(rr: FormLaunchRetrieveRequest = null): Observable<FormLaunch[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      title: rr ? rr.title : '',
    };
    return this.get<FormLaunch[]>('/api/my-form-launches?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public getTeamsFormLaunches(rr: FormLaunchRetrieveRequest = null): Observable<FormLaunch[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      title: rr ? rr.title : '',
    };
    return this.get<FormLaunch[]>('/api/teams-form-launches?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public getFormLaunchesByFormId(formId) {
    return this.get(`/api/forms/${formId}/form-launches`);
  }

  public getFormLaunchById(id) {
    return this.get(`/api/form-launches/${id}`);
  }

  public updateFormLaunch(data, id): Observable<any> {
    return this.put(`/api/form-launches/${id}`, JSON.stringify(data));
  }

  public updateLaunchStatus(data, id): Observable<any> {
    return this.put(`/api/form-launches/status/${id}`, JSON.stringify(data));
  }

  public deleteFormLaunch(id): Observable<any> {
    return this.delete(`/api/form-launches/${id}`);
  }

  public participantsBulkUploadForForm(data): Observable<any> {
    let isformData = true;
    return this.http.post(this.apiEndPoint + '/api/participant-bulk-upload-for-form', data, {
      headers: this.getHeaders(isformData),
    });
  }

  public getParticipantsExcelForForm(data) {
    return this.http.post(this.apiEndPoint + '/api/download-excel-for-form', data, {
      // responseType: 'blob',
      headers: this.getHeaders()
    });
  }

  public getParticipantsExcelForLaunch(data) {
    return this.http.post(this.apiEndPoint + '/api/download-excel-for-launch', data, {
      // responseType: 'blob',
      headers: this.getHeaders()
    });
  }

  public getParticipantExcelForLaunch(data) {
    return this.http.post(this.apiEndPoint + '/api/download-excel-for-participant', data, {
      // responseType: 'blob',
      headers: this.getHeaders()
    });
  }

  public getAllParticipantsExcelForLaunch(data) {
    return this.http.post(this.apiEndPoint + '/api/download-excel-for-participants', data, {
      // responseType: 'blob',
      headers: this.getHeaders()
    });
  }

  public getTeamSubmissions(): Observable<any> {
    return this.get(`/api/team-submissions`);
  }

  // End Added for Form Module by Surajit Pramanik

  public postParticipantsStakeholders(data:any) {
    return this.post('/api/participants_stakeholders', data);
  }

  public postassessmentActivityStakeholderCount(data:any) {
    return this.post('/api/assessment-activity-stakeholder-count', data);
  }

  public putAssessmentActivityStakeholderReplace(id:any,data:any){
    return this.put('/api/assessment-activity-stakeholder-replace/'+id,data)
  }

  public postAssessmentActivityAddStakeholder(data:any){
    return this.post('/api/assessment-activity-add-stakeholder',data)
  }

  // Succession Management API's
  public getManagerialLevel(){
    return this.get('/api/managerial_level')
  }

  public updateManagerialLevel(data:any){
    return this.post('/api/update_managerial_level',data)
  }

  public getCriticalLevel(){
    return this.get('/api/critical_level')
  }

  public updateCriticalLevel(data:any){
    return this.post('/api/update_critical_level',data)
  }

  public updateReadinessLevel(data:any){
    return this.post('/api/add_readiness_level',data)
  }

  public getParameterRoles(){
    return this.get('/api/critical_parameter_name')
  }

  public updateRoleParameter(data:any){
    return this.post('/api/update_role_parameter',data)
  }

  public getcriticalParametrNameByID(id:any){
    return this.get('/api/critical_parametr_name/'+id)
  }

  public postCreateCriticalRole(data:any){
    return this.post('/api/create_critical_role',data)
  }

  public getCriticalRolesPaginated(rr: CriticalRoleRetieveRequest): Observable<CriticalRole[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 10,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      critical_role_id: rr ? rr.critical_role_id : '',
      role_name: rr ? rr.role_name : '',
      occupied_data_needed: rr ? rr.occupied_data_needed : false,
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      department_ids: rr ? rr.department_ids : '',
      except_role_id: rr ? rr.except_role_id : '',
      managerial_level_id: rr ? rr.managerial_level_id : '',
      ids: rr ? rr.ids : '',
    };
    return this.get<CriticalRole[]>('/api/get_critical_role?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public getCriticalRoleByID(id:any){
    return this.get('/api/critical_role/'+id)
  }

    public getSuccessionManagementDashboard(rr: SMDashboardRequest): Observable<SMDashboardRequest[]> {
        let filters = {
            business_unit_id: rr ? rr.business_unit_id : '',
            function_id: rr ? rr.function_id : '',
            department_id: rr ? rr.department_id : '',
            managerial_level_id: rr ? rr.managerial_level_id : '',
        };
        return this.get<SMDashboardRequest[]>('/api/succession_management_dashboard?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
    }

  public updateCriticalRole(id:any,data:any){
    return this.put('/api/update_critical_role/'+id,data)
  }

  public getAllReadinessLevel(){
    return this.get('/api/get_all_readiness_level')
  }

  public createEmployeeMappingWithCriticalRole(data:any){
    return this.post('/api/create_employee_map',data)
  }

  public getEmployeesMappedToCriticalRolesPaginated(rr: CriticalRoleEmployeeMapRetieveRequest): Observable<CriticalRoleEmployeeMap[]> {
      let filters = {
          page: rr ? rr.page : 1,
          per_page: rr ? rr.per_page : 10,
          order_by: rr ? rr.order_by : 'created_on',
          sort_order: rr ? rr.sort_order : 'desc',
          status: rr ? rr.status : '',
          critical_role_id: rr ? rr.critical_role_id : '',
          except_list: rr ? rr.except_list : '',
          rediness_level_id  : rr? rr.rediness_level_id : '',
          variable_filter_key  : rr? rr.variable_filter_key : '',
          managerial_level_id  : rr? rr.managerial_level_id : '',


      };
      return this.get<CriticalRoleEmployeeMap[]>('/api/get_all_employee_mapping_data?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }


  public getEmployeesCriticakRoleMapUpdate(rr: CriticalRoleEmployeeMapLogRetieveRequest): Observable<CriticalRoleEmployeeMapLog[]> {
    let filters = {
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 10,
        order_by: rr ? rr.order_by : 'created_on',
        sort_order: rr ? rr.sort_order : 'desc',
        is_updated : rr ? rr.is_updated : '',
        check_update : rr ? rr.check_update : '',
        critical_role_id : rr ? rr.critical_role_id : '',
        no_incumbent : rr ? rr.no_incumbent : '',
        variable_filter_key : rr ? rr.variable_filter_key : '',
        managerial_level_id : rr ? rr.managerial_level_id : '',
    };
    return this.get<CriticalRoleEmployeeMapLog[]>('/api/get_critical_role_employee_map_updating_list?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
}



    public getEmployeeMapByID(id:any){
      return this.get('/api/get_mapping_data/'+id)
    }

    public getOccupiedEmployeeByCriticalRoleID(id:any){
      return this.get('/api/get_occupied_employee/'+id)
    }

    public updateEmployeeMapSM(id:any,data:any){
      return this.put('/api/update_employee_map/'+id,data)
    }

    public updateEmployeeMapLog(id:any,data:any){
      return this.put('/api/update_employee_map_readiness/'+id,data)
    }

    public getSampleExcelCriticalRole(){
      return this.http.get(this.apiEndPoint+'/api/critical_roleebulkuploadsample', {responseType:"blob", headers:this.getHeaders().set('Cache-Control','no-cache, no-store, must-revalidate, post-check=0, pre-check=0').set( 'Pragma','no-cache').set( 'Expires','0')} )
    }

    public getSampleExcelCriticalRoleEmployeeMap(){
      return this.http.get(this.apiEndPoint+'/api/critical_role_employee_map_bulk_upload_sample', {responseType:"blob", headers:this.getHeaders().set('Cache-Control','no-cache, no-store, must-revalidate, post-check=0, pre-check=0').set( 'Pragma','no-cache').set( 'Expires','0')} )
    }

  public criticalRoleBulkUpload(data): Observable<any> {
    let isformData = true;
    return this.http.post(this.apiEndPoint + '/api/critical_role/bulk-import', data, {
      headers: this.getHeaders(isformData),
    });
  }

  public criticalRoleEmployeeMapUpload(data): Observable<any> {
    let isformData = true;
    return this.http.post(this.apiEndPoint + '/api/critical_role_employee_map/bulk-import', data, {
      headers: this.getHeaders(isformData),
    });
  }

  public approveEmployeeMapCR(id:any,data:any,action:any){
    return this.put('/api/approve_employee_map/'+id+'/'+action,data)
  }

  public getRetirementStatus(){
    return this.get('/api/get_all_retirement_reasons')
  }

  public addRetirementReasons(data:any){
    return this.post('/api/add_retirement_reasons',data)
  }

  public criticalRoleEmployeeTerminate(data:any){
    return this.post('/api/critical_role_employee_replace-terminate',data)
  }

  public getIncumbentName(){
    return this.get('/api/get_incumbend_name')
  }


  public getEmployeesSearchClone(
    rr: EmployeeRetrieveRequest = null
  ): Observable<Employee[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      emp_ids: rr ? rr.emp_ids : '',
      display_name: rr ? rr.display_name : '',
      email_id: rr ? rr.email_id : '',
      variable_filter_key: rr ? rr.variable_filter_key : '',
      business_unit_ids: rr ? rr.business_unit_ids : '',
      function_ids: rr ? rr.function_ids : '',
      department_ids: rr ? rr.department_ids : '',
      location_ids: rr ? rr.location_ids : '',
      grade_ids: rr ? rr.grade_ids : '',
      employee_search_id: rr ? rr.employee_search_id : '',
      is_tenant_admin: rr ? rr.is_tenant_admin : '',

    };

    return this.get<Employee[]>(
      '/api/employees-search-clone?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public getEmployeesSMDashboard(
    rr: SMEmployeesDashboardRequest = null
  ): Observable<SMEmployeesDashboard[]> {
    let filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 100,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      employee_id: rr ? rr.employee_id: '',
      readiness_level_id: rr ? rr.readiness_level_id: '',
      not_incumbent: rr ? rr.not_incumbent: '',
      emp_filter: rr ? rr.emp_filter:'',
      managerial_level_id: rr ? rr.managerial_level_id:'',
    };

    return this.get<SMEmployeesDashboard[]>(
      '/api/getempdata?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public checkEmployeeOccupied(id:any){
    return this.get('/api/check_employee_occupied/'+id)
  }

  public getDownloadPreviousStakeHoldersData(id:any){
    return this.get('/api/get_stakeholder_bulk_uploaded_data/'+id)
  }

  public getSuccessionManagementReport(data:any){
    return this.http.post(this.apiEndPoint+'/api/reports_succession_management', data,{responseType:"blob", headers:this.getHeaders().set('Cache-Control','no-cache, no-store, must-revalidate, post-check=0, pre-check=0').set( 'Pragma','no-cache').set( 'Expires','0')} )
  }

  public getEmployeeVariableFilter(id:any){
    return this.get('/api/get_employee_variable_filter/'+id)
  }

  // public getSuccessMetrics(){
  //   return this.get('/api/success_metrics')
  // }


  public getSuccessMetrics(rr: SMSuccessMatrixRequest): Observable<SMSuccessMatrixRequest[]> {
    let filters = {
      managerial_level_id: rr ? rr.managerial_level_id : '',
    };
    return this.get<SMSuccessMatrixRequest[]>('/api/success_metrics?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public getAllCareerPlan(id:any){
    return this.get('/api/get_all_career_plan/'+id)
  }
  public getNineBoxHistoryAvailable(id:any){
    return this.get('/api/check_ninebox_update_available/'+id)
  }

//   New Nine Box API's

  public getSuccessionManagementReportspr(data){
    return this.http.post(this.apiEndPoint+'/api/critical_role_report',data,{responseType:"blob", headers:this.getHeaders().set('Cache-Control','no-cache, no-store, must-revalidate, post-check=0, pre-check=0').set( 'Pragma','no-cache').set( 'Expires','0')} )
  }

  public getSuccessionManagementReportcpr(data:any){
    return this.http.post(this.apiEndPoint+'/api/career_plan',data,{responseType:"blob", headers:this.getHeaders().set('Cache-Control','no-cache, no-store, must-revalidate, post-check=0, pre-check=0').set( 'Pragma','no-cache').set( 'Expires','0')} )
  }
  public updateNineBoxQuadrants(data:any,id:any){
    return this.put('/api/nine_box_update_quadrant/'+id,data)
  }

  public getNineBoxLog(id:any){
    return this.get('/api/nine_box_log/'+id)
  }

  public getCommonParticipant(data:any){
    return this.post('/api/get_common_participant_in_assessment',data)
  }

  public getAllVfEmployees(
    rr: EmployeeRetrieveRequest = null
  ): Observable<Employee[]> {
    let filters = {
      variable_filter_key: rr ? rr.variable_filter_key : '',
    };

    return this.get<Employee[]>(
      '/api/variable_filter_check?f=' + btoa(encodeURIComponent(JSON.stringify(filters)))
    );
  }

  public addEmployeesToManagerialLevel(data:any){
      return this.post('/api/employee_managerial_map',data)
  }

  public getEmployeeManagerialLevelsByID(id:any){
    return this.get('/api/get_all_emps_mangerial_level/'+id)
  }

  public deleteManagerialLevelMap(id:any){
    return this.delete('/api/delete_managerial_level_map/'+id)
  }

  public getManagerialLevelByEmployeeId(id:any){
    return this.get('/api/get_managerial_level/'+id)
  }

  public getTalentCardExcelDump(){
    return this.get('/api/get_talentcard_excel')
  }

  public getEmployeeDataExcelDump(){
    return this.get('/api/employee_data_excel')
  }

  public get_employee_data_download_status(data:any){
    return this.post('/api/get_employee_data_download_status',data)
  }

  public import_psychometric_data(data,id) {
    return this.postFormData('/api/assessment_activity_psychometric_bulk_upload/'+id, data);
  }


  public generate_performance_excel(date:any){
    return this.get('/api/generate_performance_excel/'+date)
  }


}
